import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

import { Spacer } from '../../atoms/Spacer/Spacer';
import {
  ResumeBasicStore,
  ResumeCertificationStore,
  ResumeCustomStore,
  ResumeEducationStore,
  ResumeEmploymentStore,
  ResumeLanguageStore,
  ResumeLinkStore,
  ResumeProjectStore,
  ResumeReferenceStore,
  ResumeSkillStore,
} from '../../../routes/Resume/Store';
import { getLevelLabel, getProficiencyLabel } from './helper';
import {
  ResumePreviewBlock,
  ResumePreviewCenter,
  ResumePreviewHtml,
  ResumePreviewLarge,
  ResumePreviewSmall,
  ResumePreviewSummaryContent,
  ResumePreviewlarge,
} from './ResumePreview.styles';

export const ResumePreviewScorpioBasic = ({ firstName, lastName, jobTitle }: ResumePreviewScorpioBasicProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
      }}
    >
      {firstName ? (
        <div
          style={{
            color: 'var(--design-primary-color)',
            fontWeight: 'bold',
            lineHeight: '3rem',
            fontSize: '46px',
            marginBottom: '0.2em',
          }}
        >
          {firstName}
        </div>
      ) : null}
      {lastName ? (
        <div
          style={{
            color: 'var(--design-primary-color)',
            fontWeight: 'bold',
            lineHeight: '3rem',
            fontSize: '46px',
            marginBottom: '0.2em',
          }}
        >
          {lastName}
        </div>
      ) : null}
      {jobTitle ? (
        <div
          style={{
            color: 'var(--design-secondary-color)',
            fontSize: '16px',
          }}
        >
          <i>{jobTitle}</i>
        </div>
      ) : null}
    </div>
  );
};

type ResumePreviewScorpioBasicProps = {
  name?: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
};

export const ResumePreviewScorpioContact = ({
  email,
  phone,
  fullAddress,
  linkedinUrl,
  githubUrl,
}: ResumePreviewScorpioContactProps) => {
  const linkedinPath = linkedinUrl ? linkedinUrl.split('/').pop() : '';
  const githubPath = githubUrl ? githubUrl.split('/').pop() : '';
  return (
    <ResumePreviewBlock>
      {email && (
        <>
          <ResumePreviewScorpioInline
            style={{
              fontSize: '1rem',
            }}
          >
            <ResumePreviewScorpioIcon icon={faEnvelope} />
            <b>{email}</b>
          </ResumePreviewScorpioInline>
          <Spacer y={4} />
        </>
      )}
      {phone && (
        <>
          <ResumePreviewScorpioInline
            style={{
              fontSize: '1rem',
            }}
          >
            <ResumePreviewScorpioIcon icon={faPhone} />
            <b>{phone}</b>
          </ResumePreviewScorpioInline>
          <Spacer y={4} />
        </>
      )}
      {fullAddress && (
        <>
          <ResumePreviewScorpioInline>
            <ResumePreviewScorpioIcon icon={faLocationDot} />
            <ResumePreviewSmall>{fullAddress}</ResumePreviewSmall>
          </ResumePreviewScorpioInline>
          <Spacer y={4} />
        </>
      )}
      {linkedinPath ? (
        <>
          <ResumePreviewScorpioInline>
            <ResumePreviewScorpioIcon icon={faLinkedin} />
            <ResumePreviewSmall>
              <span
                style={{
                  color: 'var(--design-primary-color)',
                }}
              >
                /in/{linkedinPath}
              </span>
            </ResumePreviewSmall>
          </ResumePreviewScorpioInline>
          <Spacer y={4} />
        </>
      ) : null}
      {githubPath ? (
        <>
          <ResumePreviewScorpioInline>
            <ResumePreviewScorpioIcon icon={faGithub} />
            <ResumePreviewSmall>
              <span
                style={{
                  color: 'var(--design-primary-color)',
                }}
              >
                /{githubPath}
              </span>
            </ResumePreviewSmall>
          </ResumePreviewScorpioInline>
          <Spacer y={4} />
        </>
      ) : null}
    </ResumePreviewBlock>
  );
};

type ResumePreviewScorpioContactProps = {
  email?: string;
  phone?: string;
  fullAddress?: string;
  linkedinUrl?: string;
  githubUrl?: string;
};

export const ResumePreviewScorpioExtra = (basic: ResumeBasicStore) => {
  const { nationality, drivingLicense, dateOfBirth, placeOfBirth, workRightUK, workRightEU, availability } = basic;
  const extra1: string[] = [];
  const extra2: string[] = [];
  if (nationality) extra1.push(`Nationality: ${nationality}`);
  if (drivingLicense) extra1.push(`Driving License: ${drivingLicense}`);
  if (dateOfBirth && placeOfBirth) {
    extra1.push(`Born on ${dateOfBirth}, ${placeOfBirth}`);
  } else {
    if (dateOfBirth) extra1.push(`Date of Birth: ${dateOfBirth}`);
    if (placeOfBirth) extra1.push(`Place of Birth: ${placeOfBirth}`);
  }
  if (workRightEU && workRightUK) {
    extra2.push(`Right to work in EU and UK`);
  } else {
    if (workRightUK) extra2.push(`Right to work in UK`);
    if (workRightEU) extra2.push(`Right to work in EU`);
  }
  if (availability) extra2.push(`Availability: ${availability}`);

  return (
    <ResumePreviewCenter
      style={{
        marginTop: '1em',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {extra1.map((extract, index) => {
          const isLast = index === extra1.length - 1;
          return (
            <div key={index}>
              <span>{extract}</span>
              {!isLast && <ResumePreviewScorpioDot />}
            </div>
          );
        })}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {extra2.map((extract, index) => {
          const isLast = index === extra2.length - 1;
          return (
            <div key={index}>
              <span>{extract}</span>
              {!isLast && <ResumePreviewScorpioDot />}
            </div>
          );
        })}
      </div>
      <div
        style={{
          height: '.7rem',
        }}
      ></div>
    </ResumePreviewCenter>
  );
};

export const ResumePreviewScorpioSummary = ({
  children,
  paragraphTextAlignment,
  hasParagraphIndendation,
}: {
  children?: React.ReactNode;
  paragraphTextAlignment: 'justify' | 'right' | 'left';
  hasParagraphIndendation: boolean;
}) => {
  if (!children) return null;
  return (
    <>
      <ResumePreviewScorpioSection>Profile</ResumePreviewScorpioSection>
      <ResumePreviewSummaryContent
        $paragraphTextAlignment={paragraphTextAlignment}
        $hasParagraphIndendation={hasParagraphIndendation}
        dangerouslySetInnerHTML={{ __html: children }}
        style={{
          marginTop: '1em',
          marginBottom: '1em',
        }}
      ></ResumePreviewSummaryContent>
    </>
  );
};

export const ResumePreviewScorpioEmployment = ({ item }: { item: ResumeEmploymentStore }) => {
  return (
    <ResumePreviewBlock
      style={{
        marginTop: '.5em',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            marginBottom: '0.2em',
            fontSize: '1.2em',
          }}
        >
          <b>
            {item.position}
            {item.position && item.company ? ', ' : null} {item.company}
            {(item.company || item.position) && item.location ? ', ' : null}
            {item.location}
          </b>
        </div>
        <div
          style={{
            color: 'var(--design-text-color-light)',
          }}
        >
          {item.startDate}
          {item.startDate && item.endDate ? ' - ' : null}
          {item.endDate}
        </div>
      </div>
      <ResumePreviewSummaryContent
        $paragraphTextAlignment={'left'}
        $hasParagraphIndendation={false}
        dangerouslySetInnerHTML={{ __html: item?.highlights }}
      ></ResumePreviewSummaryContent>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewScorpioEducation = ({ item }: { item: ResumeEducationStore }) => {
  return (
    <ResumePreviewBlock
      style={{
        marginTop: '.5em',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            marginBottom: '0.5em',
          }}
        >
          <b>
            {item.studyType}
            {item.studyType && item.area ? ' in ' : null}
            {item.area}
            {item.studyType && item.institution ? ', ' : null}
            {item.institution}
            {(item.studyType || item.area || item.institution) && item.location ? ', ' : null}
            {item.location}
          </b>
        </div>
        <div
          style={{
            color: 'var(--design-text-color-light)',
          }}
        >
          {item.startDate}
          {item.startDate && item.endDate ? ' - ' : null}
          {item.endDate}
          {(item.startDate || item.endDate || item.score) && item.score ? ' | ' : null}
          {item.score ? `Score: ${item.score}` : null}
        </div>
      </div>
      <ResumePreviewSummaryContent
        $paragraphTextAlignment={'left'}
        $hasParagraphIndendation={false}
        dangerouslySetInnerHTML={{ __html: item?.highlights }}
      ></ResumePreviewSummaryContent>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewScorpioProject = ({ item }: { item: ResumeProjectStore }) => {
  return (
    <ResumePreviewBlock
      style={{
        marginBottom: '1em',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            marginBottom: '0.5em',
          }}
        >
          <b>{item.name}</b>
        </div>
        <div
          style={{
            color: 'var(--design-text-color-light)',
          }}
        >
          {item.date}
          {item.date && item.url ? ' | ' : null}
          <span
            style={{
              color: 'var(--design-primary-color)',
            }}
          >
            {item.url}
          </span>
        </div>
      </div>
      <ResumePreviewSummaryContent
        $paragraphTextAlignment={'right'}
        $hasParagraphIndendation={false}
        dangerouslySetInnerHTML={{ __html: item?.highlights }}
      ></ResumePreviewSummaryContent>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewScorpioCertifications = ({
  title,
  items,
}: {
  title: string;
  items: ResumeCertificationStore[];
}) => {
  return (
    <div
      style={{
        marginBottom: '1em',
      }}
    >
      <ResumePreviewScorpioSection>{title}</ResumePreviewScorpioSection>
      {items.map((item, index) => {
        return (
          <ResumePreviewBlock
            key={index}
            style={{
              marginTop: '.5em',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  marginBottom: '0.5em',
                }}
              >
                <b>
                  {item.title}
                  {item.title && item.issuer ? ', ' : null}
                  {item.issuer}
                </b>
              </div>
              <div
                style={{
                  color: 'var(--design-text-color-light)',
                }}
              >
                {item.date} {item.date && item.url ? ' | ' : null}
                <span
                  style={{
                    color: 'var(--design-primary-color)',
                  }}
                >
                  {item.url}
                </span>
              </div>
            </div>
          </ResumePreviewBlock>
        );
      })}
    </div>
  );
};

export const ResumePreviewScorpioLanguages = ({ title, items }: { title: string; items: ResumeLanguageStore[] }) => {
  return (
    <>
      <>
        <ResumePreviewScorpioSection>{title}</ResumePreviewScorpioSection>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: '2rem',
            marginBottom: '1rem',
          }}
        >
          <div
            style={{
              flex: 1,
            }}
          >
            {items
              .filter((_languages, index) => {
                return index % 2 === 0;
              })
              .map(({ language, proficiency }, index) => (
                <div
                  key={index}
                  style={{
                    gap: '8pt',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '15px',
                  }}
                >
                  <div style={{ fontSize: '12pt' }}>{language}</div>
                  <div style={{ borderRadius: '4px', border: '2px solid var(--design-text-color)' }}>
                    <div
                      className="progress"
                      style={{
                        backgroundColor: 'var(--design-primary-color)',
                        height: '8px',
                        overflow: 'hidden',
                        width: `${proficiency * 20}%`,
                      }}
                    ></div>
                  </div>
                </div>
              ))}
          </div>
          <div
            style={{
              flex: 1,
            }}
          >
            {items
              .filter((_languages, index) => {
                return index % 2 === 1;
              })
              .map(({ language, proficiency }, index) => (
                <div
                  key={index}
                  style={{
                    gap: '8pt',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '15px',
                  }}
                >
                  <div style={{ fontSize: '12pt' }}>{language}</div>
                  <div style={{ borderRadius: '4px', border: '2px solid var(--design-text-color)' }}>
                    <div
                      className="progress"
                      style={{
                        backgroundColor: 'var(--design-primary-color)',
                        height: '8px',
                        overflow: 'hidden',
                        width: `${proficiency * 20}%`,
                      }}
                    ></div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </>
      <ResumePreviewScorpioSection>{title}</ResumePreviewScorpioSection>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '1em',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.1em',
            flex: '1 0',
          }}
        >
          {items
            .filter((_item, index) => {
              return index % 2 === 0;
            })
            .map((item, index) => {
              const { language, proficiency } = item;
              const languageLabel = getProficiencyLabel(proficiency);
              return (
                <ResumePreviewScorpioInline key={index}>
                  <b>{language}</b>:<span>{languageLabel}</span>
                </ResumePreviewScorpioInline>
              );
            })}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.1em',
            flex: '1 0',
          }}
        >
          {items
            .filter((_item, index) => {
              return index % 2 === 1;
            })
            .map((item, index) => {
              const { language, proficiency } = item;
              const languageLabel = getProficiencyLabel(proficiency);
              return (
                <ResumePreviewScorpioInline key={index}>
                  <b>{language}</b>:<span>{languageLabel}</span>
                </ResumePreviewScorpioInline>
              );
            })}
        </div>
      </div>
    </>
  );
};

export const ResumePreviewScorpioHobby = ({ title, content }: { title: string; content: string }) => {
  return (
    <>
      <ResumePreviewScorpioSection>{title}</ResumePreviewScorpioSection>
      <ResumePreviewHtml content={content} />
    </>
  );
};

export const ResumePreviewScorpioSkills = ({ title, items }: { title: string; items: ResumeSkillStore[] }) => {
  return (
    <>
      <ResumePreviewScorpioSection>{title}</ResumePreviewScorpioSection>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: '2rem',
          marginBottom: '1rem',
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          {items
            .filter((_skill, index) => {
              return index % 2 === 0;
            })
            .map(({ name, level }, index) => (
              <div
                key={index}
                style={{
                  gap: '8pt',
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '15px',
                }}
              >
                <div style={{ fontSize: '12pt' }}>{name}</div>
                <div style={{ borderRadius: '4px', border: '2px solid var(--design-text-color)' }}>
                  <div
                    className="progress"
                    style={{
                      backgroundColor: 'var(--design-primary-color)',
                      height: '8px',
                      overflow: 'hidden',
                      width: `${level * 20}%`,
                    }}
                  ></div>
                </div>
              </div>
            ))}
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          {items
            .filter((_skill, index) => {
              return index % 2 === 1;
            })
            .map(({ name, level }, index) => (
              <div
                key={index}
                style={{
                  gap: '8pt',
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '15px',
                }}
              >
                <div style={{ fontSize: '12pt' }}>{name}</div>
                <div style={{ borderRadius: '4px', border: '2px solid var(--design-text-color)' }}>
                  <div
                    className="progress"
                    style={{
                      backgroundColor: 'var(--design-primary-color)',
                      height: '8px',
                      overflow: 'hidden',
                      width: `${level * 20}%`,
                    }}
                  ></div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export const ResumePreviewScorpioReferences = ({
  title,
  items,
  onDemand,
}: {
  title: string;
  items: ResumeReferenceStore[];
  onDemand?: boolean;
}) => {
  return (
    <>
      <ResumePreviewScorpioSection>{title}</ResumePreviewScorpioSection>
      {onDemand ? (
        <b>References available on demand</b>
      ) : (
        items.map((item, index) => {
          const { name, email, phone, company, linkedIn } = item;
          return (
            <table
              key={index}
              style={{
                width: '100%',
                marginBottom: '1rem',
                borderCollapse: 'collapse',
                margin: '1rem 0',
              }}
            >
              <tr>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                  }}
                >
                  {name}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                  }}
                >
                  {company}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                  }}
                >
                  {email}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                  }}
                >
                  {phone}
                </td>
              </tr>
              <tr>
                <td
                  colspan={2}
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                    textAlign: 'center',
                  }}
                >
                  LinkedIn:
                  <span
                    style={{
                      color: 'var(--design-primary-color)',
                    }}
                  >
                    {' '}
                    : {linkedIn}
                  </span>
                </td>
              </tr>
            </table>
          );
        })
      )}
      <Spacer y={2} />
    </>
  );
};

export const ResumePreviewScorpioLinks = ({ title, items }: { title: string; items: ResumeLinkStore[] }) => {
  return (
    <>
      <ResumePreviewScorpioSection>{title}</ResumePreviewScorpioSection>
      <ResumePreviewScorpioInline>
        {items.map((item, index) => {
          const { text, url } = item;
          return (
            <ResumePreviewScorpioInline key={index}>
              <b>{text}</b>:<span>{url}</span>
            </ResumePreviewScorpioInline>
          );
        })}
      </ResumePreviewScorpioInline>
    </>
  );
};

export const ResumePreviewScorpioCustom = ({ item }: { item: ResumeCustomStore }) => {
  const { header, subheader, highlights } = item;
  return (
    <ResumePreviewBlock>
      <ResumePreviewLarge>{header}</ResumePreviewLarge>
      <ResumePreviewlarge>{subheader}</ResumePreviewlarge>
      <>
        {highlights?.map((highlight, index) => {
          return <ResumePreviewHtml key={index} content={highlight as unknown as string} />;
        })}
      </>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewScorpioPageBreak = styled.div``;
ResumePreviewScorpioPageBreak.displayName = 'PageBreak';

export const ResumePreviewScorpioSection = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1em',
        fontSize: '1.8em',
        gap: '.5em',
        backgroundColor: 'var(--design-primary-color)',
        width: 'fit-content',
        padding: '.2em .5em',
      }}
    >
      {children}
    </div>
  );
};

/* Custom styles */
const ResumePreviewScorpioDot = styled.span`
  display: inline-block;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;
  height: 5px;

  &:after {
    content: '';
    position: absolute;
    font-size: 1.6rem;
    height: 2px;
    width: 2px;
    background-color: var(--design-primary-color);
    border-radius: 50%;
  }
`;

const ResumePreviewScorpioBothSide = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ResumePreviewScorpioInline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.4em;
`;

export const ResumePreviewScorpioHeader = styled.div`
  display: flex;
`;

export const ResumePreviewScorpioHeaderLeft = styled.div`
  flex: 7 0;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

export const ResumePreviewScorpioHeaderRight = styled.div`
  flex: 10 0;
`;

const ResumePreviewScorpioIcon = styled(FontAwesomeIcon)`
  color: var(--design-primary-color);
  width: 0.9em;
`;
