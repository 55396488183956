import { DesignColorPalette } from '../generated/types';

export function getColorPalette(colorPalette: DesignColorPalette): {
  primaryColor: string;
  secondaryColor: string;
  contrastColor: string;
  backgroundColor: string;
  textColor: string;
  textColorLight: string;
  headerColor: string;
  sidebarBackgroundColor: string;
  sidebarTextColor: string;
  sidebarHeaderColor: string;
} {
  let primaryColor = '#000000';
  let secondaryColor = '#000000';
  let contrastColor = '#000000';
  let backgroundColor = '#FFFFFF';
  let textColor = '#000000';
  let textColorLight = '#000000';
  let headerColor = '#000000';
  let sidebarBackgroundColor = '#FFFFFF';
  let sidebarTextColor = '#000000';
  let sidebarHeaderColor = '#000000';

  switch (colorPalette) {
    case 'DEFAULT': // Goya
      backgroundColor = '#FFFFFF';
      sidebarBackgroundColor = '#FFFFFF';
      headerColor = '#000000';
      textColor = '#000000';
      textColorLight = '#666666';
      sidebarHeaderColor = '#000000';
      sidebarTextColor = '#000000';
      primaryColor = '#000000';
      secondaryColor = '#000000';
      contrastColor = '#FFFFFF';
      break;

    case 'STANDARD1': // Matisse
      backgroundColor = '#FFFFFF';
      sidebarBackgroundColor = '#FFFFFF';
      headerColor = '#aaaaaa';
      textColor = '#000000';
      textColorLight = '#666666';
      sidebarHeaderColor = '#aaaaaa';
      sidebarTextColor = '#000000';
      primaryColor = '#4c9696';
      secondaryColor = '#bfd8d2';
      contrastColor = '#CFFFFF';
      break;

    case 'STANDARD2': // Picasso
      backgroundColor = '#FFFFFF';
      sidebarBackgroundColor = '#ffae14';
      headerColor = '#000000';
      textColor = '#333333';
      textColorLight = '#707070';
      sidebarHeaderColor = '#000000';
      sidebarTextColor = '#333333';
      primaryColor = '#cc2323';
      secondaryColor = '#ff793f';
      contrastColor = '#fff6e7';
      break;

    case 'LIGHT1': // Cézanne
      backgroundColor = '#FFFFFF';
      sidebarBackgroundColor = '#9feeff';
      headerColor = '#000000';
      textColor = '#0f1719';
      textColorLight = '#575c5e';
      sidebarHeaderColor = '#000000';
      sidebarTextColor = '#0f1719';
      primaryColor = '#9fb9ff';
      secondaryColor = '#9fc7ff';
      contrastColor = '#2f474c';
      break;
    case 'LIGHT2': // Magritte
      backgroundColor = '#FFFFFF';
      sidebarBackgroundColor = '#e1e6e8';
      headerColor = '#2d2e2e';
      textColor = '#161717';
      textColorLight = '#5b5c5c';
      sidebarHeaderColor = '#2d2e2e';
      sidebarTextColor = '#161717';
      primaryColor = '#b8bcc8';
      secondaryColor = '#d0d1d5';
      contrastColor = '#2d2e2e';
      break;
    case 'BACKGROUND1': // Munch
      backgroundColor = '#f9f4ef';
      sidebarBackgroundColor = '#003333';
      headerColor = '#000000';
      textColor = '#000000';
      textColorLight = '#666666';
      sidebarHeaderColor = '#EFEFEF';
      sidebarTextColor = '#FFFFFF';
      primaryColor = '#cc3300';
      secondaryColor = '#cc6600';
      contrastColor = '#000a0a';
      break;
    case 'BACKGROUND2': // Dalí
      backgroundColor = '#fef9db';
      sidebarBackgroundColor = '#fdee94';
      headerColor = '#322f1d';
      textColor = '#19170e';
      textColorLight = '#5e5c56';
      sidebarHeaderColor = '#000000';
      sidebarTextColor = '#19170e';
      primaryColor = '#fbe24c';
      secondaryColor = '#669999';
      contrastColor = '#334c4c';
      break;

    case 'DARK1': // Vermeer
      backgroundColor = '#FFFFFF';
      sidebarBackgroundColor = '#deb15c';
      headerColor = '#68817d';
      textColor = '#162029';
      textColorLight = '#5b6269';
      sidebarHeaderColor = '#000000';
      sidebarTextColor = '#5c89de';
      primaryColor = '#7b241b';
      secondaryColor = '#d2935d';
      contrastColor = '#faf4ee';
      break;

    case 'DARK2': // Degas
      backgroundColor = '#cfcfcf';
      sidebarBackgroundColor = '#bdb6a2';
      headerColor = '#4a453a';
      textColor = '#0a0908';
      textColorLight = '#535252';
      sidebarHeaderColor = '#FFFFFF';
      sidebarTextColor = '#0a0908';
      primaryColor = '#6b6353';
      secondaryColor = '#9c9285';
      contrastColor = '#FFFFFF';
      break;
  }

  return {
    primaryColor,
    secondaryColor,
    contrastColor,
    backgroundColor,
    textColor,
    textColorLight,
    headerColor,
    sidebarBackgroundColor,
    sidebarHeaderColor,
    sidebarTextColor,
  };
}
