import { Editor, Descendant } from 'slate';

import { ExtendedReactEditor } from './SlateEditor.types';

export const convertToSlate = (content: string): Descendant[] => {
  // split by line break
  const lines = content.split('\n');
  // convert to slate format
  const slate = lines.map((line) => {
    return {
      type: 'paragraph',
      children: [{ text: line }],
    };
  });
  return slate;
};

export const CustomEditor = {
  isBoldMarkActive(editor: ExtendedReactEditor) {
    const marks = Editor.marks(editor);
    return marks ? marks.bold === true : false;
  },

  toggleBoldMark(editor: ExtendedReactEditor) {
    const isActive = CustomEditor.isBoldMarkActive(editor);
    if (isActive) {
      Editor.removeMark(editor, 'bold');
    } else {
      Editor.addMark(editor, 'bold', true);
    }
  },
};

export const countWords = (text: string) => {
  const words = text.trim().split(/\s+/);
  return words.length;
};

export const serializeSlateNodeToHtml = (node: any): any => {
  if (isArray(node)) {
    return node.map((n: Descendant) => serializeSlateNodeToHtml(n)).join('');
  } else if (typeof node === 'object' && node.type) {
    switch (node.type) {
      case 'bulleted-list':
        return `<ul>${serializeSlateNodeToHtml(node.children)}</ul>`;
      case 'numbered-list':
        return `<ol>${serializeSlateNodeToHtml(node.children)}</ol>`;
      case 'quote':
        return `<blockquote><p>${serializeSlateNodeToHtml(node.children)}</p></blockquote>`;
      case 'paragraph': {
        const align = node.align ? ` style="text-align: ${node.align}"` : '';
        return `<p ${align}>${serializeSlateNodeToHtml(node.children)}</p>`;
      }
      case 'list-item': {
        const align = node.align ? ` style="text-align: ${node.align}"` : '';
        return `<li ${align}>${serializeSlateNodeToHtml(node.children)}</li>`;
      }
      default:
        return `<p>${serializeSlateNodeToHtml(node.children)}</p>`;
    }
  } else if (typeof node === 'object' && node.text) {
    let result = node.text;
    if (node.type === 'list-item') {
      result = `<li>${result}</li>`;
    }
    if (node.bold) {
      result = `<strong>${result}</strong>`;
    }
    if (node.italic) {
      result = `<em>${result}</em>`;
    }
    if (node.underline) {
      result = `<u>${result}</u>`;
    }

    return result;
  }
};

export const serializeSlateNodeToSection = (node: any): any => {
  if (isArray(node)) {
    return node.map((n: Descendant) => serializeSlateNodeToHtml(n));
  } else if (typeof node === 'object' && node.type) {
    switch (node.type) {
      case 'bulleted-list': {
        return `<ul>${serializeSlateNodeToHtml(node.children)}</ul>`;
      }
      case 'numbered-list': {
        return `<ol>${serializeSlateNodeToHtml(node.children)}</ol>`;
      }
      case 'quote': {
        return `<blockquote><p>${serializeSlateNodeToHtml(node.children)}</p></blockquote>`;
      }
      case 'paragraph': {
        const align = node.align ? ` style="text-align: ${node.align}"` : '';
        return `<p ${align}>${serializeSlateNodeToHtml(node.children)}</p>`;
      }
      case 'list-item': {
        const align = node.align ? ` style="text-align: ${node.align}"` : '';
        return `<li ${align}>${serializeSlateNodeToHtml(node.children)}</li>`;
      }
      default:
        return `<p>${serializeSlateNodeToHtml(node.children)}</p>`;
    }
  } else if (typeof node === 'object' && node.text) {
    let result = node.text;
    if (node.bold) {
      result = `<strong>${result}</strong>`;
    }
    if (node.italic) {
      result = `<em>${result}</em>`;
    }
    if (node.underline) {
      result = `<u>${result}</u>`;
    }

    return result;
  }
};

export const serializeSlateNodeToText = (node: any): any => {
  if (isArray(node)) {
    return node.map((n: Descendant) => serializeSlateNodeToText(n)).join('');
  } else if (typeof node === 'object' && node.type) {
    switch (node.type) {
      case 'quote':
        return `${serializeSlateNodeToText(node.children)}\n`;
      case 'paragraph':
        return `${serializeSlateNodeToText(node.children)}\n`;
      default:
        return `${serializeSlateNodeToText(node.children)}\n`;
    }
  } else if (typeof node === 'object' && node.text) {
    let result = node.text;

    return result;
  }
};

const isArray = (a: any) => {
  return Array.isArray(a);
};
