import { useEffect, useId, useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { StyleSheetManager } from 'styled-components';

import { FunctionalIFrameComponentProps } from './FunctionalIFrameComponent.types';

const RATIO = 792;
const HEIGHT = 1122;
const IFRAMEWIDTH = 792;

export const FunctionalIFrameComponent = ({
  children,
  title,
  ...otherProps
}: React.PropsWithChildren<FunctionalIFrameComponentProps>) => {
  const iframeId = useId();
  const [contentRef, setContentRef] = useState(null);
  const doc = contentRef?.contentWindow?.document;
  const mountNode = doc?.body;
  const insertionTarget = doc?.createElement('link');
  if (insertionTarget) {
    doc.head.append(insertionTarget);
  }

  useEffect(() => {
    window.addEventListener('resize', updateIframeSize);
    window.addEventListener('DOMContentLoaded', updateIframeSize);
    updateIframeSize();
    return () => {
      window.removeEventListener('resize', updateIframeSize);
      window.removeEventListener('DOMContentLoaded', updateIframeSize);
    };
  }, []);

  function updateIframeSize() {
    const container = document.getElementById(`iframeContainer-${iframeId}`);
    const iframe = document.getElementById(`iframe-${iframeId}`);
    const iframeInner = document.getElementById(`iframeInner-${iframeId}`);

    const containerWidth = container?.offsetWidth;
    const containerHeight = container?.offsetHeight;

    if (!containerWidth || !containerHeight || !iframe || !iframeInner) {
      return;
    }

    const widthScale = containerWidth / RATIO;

    iframeInner.style.transform = `scale(${widthScale})`;
    container.style.height = `${HEIGHT * widthScale}px`;
  }

  return (
    <Container id={`iframeContainer-${iframeId}`}>
      <Inner id={`iframeInner-${iframeId}`}>
        <iframe
          style={{
            transform: 'scale(1)',
          }}
          width={IFRAMEWIDTH}
          height="2268"
          tabIndex="-1"
          title={title}
          {...otherProps}
          ref={setContentRef}
          id={`iframe-${iframeId}`}
        >
          {mountNode &&
            createPortal(<StyleSheetManager target={insertionTarget}>{children}</StyleSheetManager>, mountNode)}
        </iframe>
      </Inner>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  position: relative;
  margin: 0 auto;
  height: 600px;
  overflow: hidden;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  border-radius: 4px;

  iframe {
    border: 0; /* Remove the default border */
    margin: 0; /* Remove any default margin */
    padding: 0; /* Remove any default padding */
    display: block; /* Ensure it's treated as a block-level element */
  }
`;

const Inner = styled.div`
  display: block;
  transform-origin: 0 0;
  position: absolute;
  margin: 0;
  padding: 0;
`;
