import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import { Spacer } from '../../atoms/Spacer/Spacer';
import {
  ResumeBasicStore,
  ResumeCertificationStore,
  ResumeCustomStore,
  ResumeEducationStore,
  ResumeEmploymentStore,
  ResumeLanguageStore,
  ResumeLinkStore,
  ResumeProjectStore,
  ResumeReferenceStore,
  ResumeSkillStore,
} from '../../../routes/Resume/Store';
import {
  ResumePreviewBlock,
  ResumePreviewCenter,
  ResumePreviewHtml,
  ResumePreviewHuge,
  ResumePreviewLARGE,
  ResumePreviewLarge,
  ResumePreviewSummaryContent,
  ResumePreviewlarge,
} from './ResumePreview.styles';

export const ResumePreviewCancerBasic = ({ name, jobTitle }: { name?: string; jobTitle?: string }) => {
  const nameLength = name?.length || 0;
  const fontSize = nameLength > 12 ? '32pt' : '46pt';
  return (
    <ResumePreviewCenter
      style={{
        marginTop: '3rem',
        marginBottom: '2rem',
        padding: '2rem',
        borderRadius: '4pt',
        background: 'var(--design-sidebar-background-color)',
      }}
    >
      {name ? (
        <ResumePreviewHuge
          style={{ fontSize, textTransform: 'uppercase', color: 'var(--design-sidebar-header-color)' }}
        >
          {name}
        </ResumePreviewHuge>
      ) : null}
      <Spacer y={16} />
      {jobTitle ? (
        <ResumePreviewLARGE
          style={{ fontSize: '20pt', textTransform: 'uppercase', color: 'var(--design-sidebar-text-color)' }}
        >
          {jobTitle}
        </ResumePreviewLARGE>
      ) : null}
    </ResumePreviewCenter>
  );
};

export const ResumePreviewCancerContact = ({
  email,
  phone,
  fullAddress,
  linkedinUrl,
  githubUrl,
}: ResumePreviewCancerContactProps) => {
  const linkedinPath = linkedinUrl ? linkedinUrl.split('/').pop() : '';
  const githubPath = githubUrl ? githubUrl.split('/').pop() : '';
  return (
    <ResumePreviewBlock>
      {email && (
        <>
          <ResumePreviewCancerInline>
            <ResumePreviewCancerIcon icon={faEnvelope} />
            {email}
          </ResumePreviewCancerInline>
          <Spacer y={4} />
        </>
      )}
      {phone && (
        <>
          <ResumePreviewCancerInline>
            <ResumePreviewCancerIcon icon={faPhone} />
            {phone}
          </ResumePreviewCancerInline>
          <Spacer y={4} />
        </>
      )}
      {fullAddress && (
        <>
          <ResumePreviewCancerInline>
            <ResumePreviewCancerIcon icon={faEnvelope} />
            {fullAddress}
          </ResumePreviewCancerInline>
          <Spacer y={4} />
        </>
      )}
      {linkedinPath ? (
        <>
          <ResumePreviewCancerInline>
            <ResumePreviewCancerIcon icon={faLinkedin} /> {linkedinPath}
          </ResumePreviewCancerInline>
          <Spacer y={4} />
        </>
      ) : null}
      {githubPath ? (
        <>
          <ResumePreviewCancerInline>
            <ResumePreviewCancerIcon icon={faGithub} /> {githubPath}
          </ResumePreviewCancerInline>
          <Spacer y={4} />
        </>
      ) : null}
    </ResumePreviewBlock>
  );
};

type ResumePreviewCancerContactProps = {
  email?: string;
  phone?: string;
  fullAddress?: string;
  linkedinUrl?: string;
  githubUrl?: string;
};

export const ResumePreviewCancerExtra = (basic: ResumeBasicStore) => {
  const { nationality, drivingLicense, dateOfBirth, placeOfBirth, workRightUK, workRightEU, availability } = basic;
  let dateOfBirthNode = null;
  if (dateOfBirth) {
    dateOfBirthNode = (
      <>
        <ResumePreviewCancerSection>Date of Birth</ResumePreviewCancerSection>
        <ResumePreviewBlock>{dateOfBirth}</ResumePreviewBlock>
        <Spacer y={10} />
      </>
    );
  }
  if (placeOfBirth) {
    dateOfBirthNode = (
      <>
        <ResumePreviewCancerSection>Place of Birth</ResumePreviewCancerSection>
        <ResumePreviewBlock>{placeOfBirth}</ResumePreviewBlock>
        <Spacer y={10} />
      </>
    );
  }
  if (dateOfBirth && placeOfBirth) {
    dateOfBirthNode = (
      <>
        <ResumePreviewCancerSection>Date of Birth</ResumePreviewCancerSection>
        <ResumePreviewBlock>
          {dateOfBirth}, {placeOfBirth}
        </ResumePreviewBlock>
        <Spacer y={10} />
      </>
    );
  }

  let workingRightNode = null;
  if (workRightUK) {
    workingRightNode = (
      <>
        <ResumePreviewCancerSection>Working Right</ResumePreviewCancerSection>
        <ResumePreviewBlock>UK</ResumePreviewBlock>
        <Spacer y={10} />
      </>
    );
  }
  if (workRightEU) {
    workingRightNode = (
      <>
        <ResumePreviewCancerSection>Working Right</ResumePreviewCancerSection>
        <ResumePreviewBlock>EU</ResumePreviewBlock>
        <Spacer y={10} />
      </>
    );
  }
  if (workRightUK && workRightEU) {
    workingRightNode = (
      <>
        <ResumePreviewCancerSection>Working Right</ResumePreviewCancerSection>
        <ResumePreviewBlock>EU and UK</ResumePreviewBlock>
        <Spacer y={10} />
      </>
    );
  }

  return (
    <>
      <ResumePreviewBlock>
        {nationality ? (
          <>
            <ResumePreviewCancerSection>Nationality</ResumePreviewCancerSection>
            <ResumePreviewBlock>{nationality}</ResumePreviewBlock>
            <Spacer y={10} />
          </>
        ) : null}
        {drivingLicense ? (
          <>
            <ResumePreviewCancerSection>Driving License</ResumePreviewCancerSection>
            <ResumePreviewBlock>{drivingLicense}</ResumePreviewBlock>
            <Spacer y={10} />
          </>
        ) : null}

        {dateOfBirthNode}
        {workingRightNode}
        {availability ? (
          <>
            <ResumePreviewCancerSection>Availability</ResumePreviewCancerSection>
            <ResumePreviewBlock>{availability}</ResumePreviewBlock>
            <Spacer y={10} />
          </>
        ) : null}
      </ResumePreviewBlock>
    </>
  );
};

export const ResumePreviewCancerSummary = ({
  children,
  paragraphTextAlignment,
  hasParagraphIndendation,
}: {
  children?: React.ReactNode;
  paragraphTextAlignment: 'justify' | 'right' | 'left';
  hasParagraphIndendation: boolean;
}) => {
  if (!children) return null;
  return (
    <ResumePreviewSummaryContent
      $paragraphTextAlignment={paragraphTextAlignment}
      $hasParagraphIndendation={hasParagraphIndendation}
      dangerouslySetInnerHTML={{ __html: children }}
    ></ResumePreviewSummaryContent>
  );
};

export const ResumePreviewCancerEmployment = ({ item }: { item: ResumeEmploymentStore }) => {
  return (
    <ResumePreviewBlock>
      <div>
        <span style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>{item.position}, </span>
        <span style={{ textTransform: 'uppercase' }}>{item.company}</span>
      </div>
      <div>
        <span>{item.location}</span>
        {item.location && (item.startDate || item.endDate) && <span>|</span>}
        <i>
          {item.startDate} - {item.endDate}
        </i>
      </div>
      <ResumePreviewSummaryContent
        style={{ marginTop: '.7rem', marginBottom: '1rem' }}
        $paragraphTextAlignment={'left'}
        $hasParagraphIndendation={false}
        dangerouslySetInnerHTML={{ __html: item?.highlights }}
      ></ResumePreviewSummaryContent>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewCancerEducation = ({ item }: { item: ResumeEducationStore }) => {
  return (
    <ResumePreviewBlock>
      <ResumePreviewCancerBothSide>
        <b>{item.institution}</b>
        <div>{item.location}</div>
      </ResumePreviewCancerBothSide>
      <ResumePreviewCancerBothSide>
        <ResumePreviewCancerInline>
          <i>{item.studyType}</i>
          <i>{item.area}</i>
          <i>{item.score}</i>
        </ResumePreviewCancerInline>
        <div>
          {item.startDate} - {item.endDate}
        </div>
      </ResumePreviewCancerBothSide>
      <ResumePreviewSummaryContent
        $paragraphTextAlignment={'left'}
        $hasParagraphIndendation={false}
        dangerouslySetInnerHTML={{ __html: item?.highlights }}
      ></ResumePreviewSummaryContent>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewCancerProject = ({ item }: { item: ResumeProjectStore }) => {
  return (
    <ResumePreviewBlock>
      <ResumePreviewCancerInline>
        <b style={{ textTransform: 'uppercase' }}>{item.name}</b>
        <span style={{ textTransform: 'uppercase' }}>{item.date}</span>
        {item.url && (
          <>
            |<span style={{ textTransform: 'uppercase', color: 'var(--design-primary-color)' }}>LINK</span>
          </>
        )}
      </ResumePreviewCancerInline>
      <ResumePreviewCancerInline>
        <span>{item.url}</span>
      </ResumePreviewCancerInline>
      <ResumePreviewSummaryContent
        $paragraphTextAlignment={'left'}
        $hasParagraphIndendation={false}
        dangerouslySetInnerHTML={{ __html: item?.highlights }}
      ></ResumePreviewSummaryContent>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewCancerCertifications = ({
  title,
  items,
}: {
  title: string;
  items: ResumeCertificationStore[];
}) => {
  return (
    <>
      <ResumePreviewCancerSection>{title}</ResumePreviewCancerSection>
      {items.map((item, index) => {
        return (
          <ResumePreviewBlock key={index}>
            <ResumePreviewCancerBothSide>
              <b>{item.title}</b>
              <span>{item.issuer}</span>
            </ResumePreviewCancerBothSide>
            <ResumePreviewCancerBothSide>
              <span>{item.url}</span>
              <span>{item.date}</span>
            </ResumePreviewCancerBothSide>
          </ResumePreviewBlock>
        );
      })}
    </>
  );
};

export const ResumePreviewCancerLanguages = ({ title, items }: { title: string; items: ResumeLanguageStore[] }) => {
  const skills = items.map(({ language, proficiency }, index) => (
    <>
      <div key={index} style={{ display: 'flex', marginBottom: '10px' }}>
        <div style={{ width: '40%', display: 'inline-block' }}>{language}</div>
        <div style={{ width: '60%', display: 'inline-block' }}>
          {Array.from({ length: 5 }).map((_, i) => (
            <FontAwesomeIcon
              key={i}
              icon={faCircle}
              style={{
                color: i < proficiency ? 'var(--design-primary-color)' : 'var(--design-text-color)',
                width: '10px',
                marginRight: '5px',
              }}
            />
          ))}
        </div>
      </div>
    </>
  ));

  return (
    <>
      <ResumePreviewCancerSection>{title}</ResumePreviewCancerSection>
      {skills}
    </>
  );
};

export const ResumePreviewCancerHobby = ({ title, content }: { title: string; content: string }) => {
  return (
    <>
      <ResumePreviewCancerSection>{title}</ResumePreviewCancerSection>
      <ResumePreviewHtml content={content} />
    </>
  );
};

export const ResumePreviewCancerSkills = ({ title, items }: { title: string; items: ResumeSkillStore[] }) => {
  return (
    <>
      <ResumePreviewCancerSection>{title}</ResumePreviewCancerSection>
      <>
        {items.map(({ name, level }, index) => (
          <div
            key={index}
            style={{
              gap: '8pt',
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '15px',
            }}
          >
            <div style={{ fontSize: '12pt' }}>{name}</div>
            <div style={{ borderRadius: '4px', border: '2px solid var(--design-text-color)' }}>
              <div
                className="progress"
                style={{
                  backgroundColor: 'var(--design-primary-color)',
                  height: '8px',
                  overflow: 'hidden',
                  width: `${level * 20}%`,
                }}
              ></div>
            </div>
          </div>
        ))}
      </>
    </>
  );
};

export const ResumePreviewCancerReferences = ({
  title,
  items,
  onDemand,
}: {
  title: string;
  items: ResumeReferenceStore[];
  onDemand?: boolean;
}) => {
  return (
    <>
      <ResumePreviewCancerSection>{title}</ResumePreviewCancerSection>
      {onDemand ? (
        <b>References available on demand</b>
      ) : (
        items.map((item, index) => {
          const { name, email, phone, company, linkedIn } = item;
          return (
            <div key={index}>
              <div>{name}</div>
              <div>{company}</div>
              <div>{email}</div>
              <div>{phone}</div>
              <div>
                <span
                  style={{
                    color: 'var(--design-primary-color)',
                  }}
                >
                  {linkedIn}
                </span>
              </div>
            </div>
          );
        })
      )}
      <Spacer y={2} />
    </>
  );
};

export const ResumePreviewCancerLinks = ({ title, items }: { title: string; items: ResumeLinkStore[] }) => {
  return (
    <>
      <ResumePreviewCancerSection>{title}</ResumePreviewCancerSection>
      <ResumePreviewCancerInline>
        {items.map((item, index) => {
          const { text, url } = item;
          return (
            <ResumePreviewCancerInline key={index}>
              <b>{text}</b>:<span>{url}</span>
            </ResumePreviewCancerInline>
          );
        })}
      </ResumePreviewCancerInline>
    </>
  );
};

export const ResumePreviewCancerCustoms = ({ title, items }: { title?: string; items: ResumeCustomStore[] }) => {
  return (
    <>
      {/* <pre>{JSON.stringify(title, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(items, null, 2)}</pre> */}
      <ResumePreviewCancerSection>{title}</ResumePreviewCancerSection>
      {items.map((item, index) => {
        const { header, subheader, highlights } = item;
        return (
          <ResumePreviewBlock key={index}>
            <ResumePreviewLarge>{header}</ResumePreviewLarge>
            <ResumePreviewlarge>{subheader}</ResumePreviewlarge>
            <>
              {highlights?.map((highlight, index) => {
                return <ResumePreviewHtml key={index} content={highlight as unknown as string} />;
              })}
            </>
          </ResumePreviewBlock>
        );
      })}
    </>
  );
};

export const ResumePreviewCancerPageBreak = styled.div``;
ResumePreviewCancerPageBreak.displayName = 'PageBreak';

export const ResumePreviewCancerSection = styled.div`
  border-bottom: 1pt solid var(--design-primary-color);
  font-size: 1.2em;
  line-height: 1.2em;
  margin-bottom: 0.5em;
  color: var(--design-header-color);
  margin-top: 1em;
`;

export const ResumePreviewCancerColoredBlock = styled.div`
  margin: 0 auto;
  width: 80%;
  background-color: var(--design-primary-color);
  height: 8px;
  margin-bottom: 1rem;
`;

/* Custom styles */
const ResumePreviewCancerListItems = styled.li`
  margin: 0;
  padding: 0;
  &::marker {
    color: var(--design-primary-color);
  }
`;

export const ResumePreviewCancerDot = styled.span`
  border-left: 1px solid black;
  display: inline-block;
  align-items: center;
  justify-content: center;
  height: 8px;
  width: 8px;
  margin: 0 0.25em 0 0.5em;
`;

export const ResumePreviewCancerSpan = styled.span``;

export const ResumePreviewCancerInline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.4em;
`;

export const ResumePreviewCancerBothSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ResumePreviewCancerColumns = styled.div`
  display: flex;
  flex-direction: row;
  color: grey;
`;

export const ResumePreviewCancerColumnLeft = styled.div`
  flex: 33%;
`;

export const ResumePreviewCancerColumnRight = styled.div`
  flex: 66%;
`;

const ResumePreviewCancerIcon = styled(FontAwesomeIcon)`
  color: var(--design-primary-color);
  width: 1em;
`;
