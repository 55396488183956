import styled from 'styled-components';

import { Spacer } from '../../atoms/Spacer/Spacer';
import {
  ResumeBasicStore,
  ResumeCertificationStore,
  ResumeCustomStore,
  ResumeEducationStore,
  ResumeEmploymentStore,
  ResumeLanguageStore,
  ResumeLinkStore,
  ResumeProjectStore,
  ResumeReferenceStore,
  ResumeSkillStore,
} from '../../../routes/Resume/Store';
import { getLevelLabel, getProficiencyLabel } from './helper';
import {
  ResumePreviewBlock,
  ResumePreviewCenter,
  ResumePreviewHtml,
  ResumePreviewLARGE,
  ResumePreviewLarge,
  ResumePreviewSmall,
  ResumePreviewSummaryContent,
  ResumePreviewlarge,
} from './ResumePreview.styles';

export const ResumePreviewVirgoBasic = ({ name, jobTitle }: { name?: string; jobTitle?: string }) => {
  return (
    <ResumePreviewCenter>
      {name ? <ResumePreviewLARGE>{name}</ResumePreviewLARGE> : null}
      {jobTitle ? <ResumePreviewLarge>{jobTitle}</ResumePreviewLarge> : null}
      <div
        style={{
          height: '1.5rem',
        }}
      ></div>
    </ResumePreviewCenter>
  );
};

export const ResumePreviewVirgoContact = ({
  email,
  phone,
  fullAddress,
  linkedinUrl,
  githubUrl,
}: ResumePreviewVirgoContactProps) => {
  const contacts: string[] = [];
  if (email) contacts.push(email);
  if (phone) contacts.push(phone);
  if (fullAddress) contacts.push(fullAddress);

  return (
    <ResumePreviewCenter>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {contacts.map((contact, index) => {
          const isLast = index === contacts.length - 1;
          return (
            <div key={index}>
              <b>{contact}</b>
              {!isLast && <ResumePreviewVirgoDot />}
            </div>
          );
        })}
      </div>
      <div>{linkedinUrl ? <span>{linkedinUrl}</span> : null}</div>
      <div>{githubUrl ? <span>{githubUrl}</span> : null}</div>
    </ResumePreviewCenter>
  );
};

type ResumePreviewVirgoContactProps = {
  email?: string;
  phone?: string;
  fullAddress?: string;
  linkedinUrl?: string;
  githubUrl?: string;
};

export const ResumePreviewVirgoExtra = (basic: ResumeBasicStore) => {
  const { nationality, drivingLicense, dateOfBirth, placeOfBirth, workRightUK, workRightEU, availability } = basic;
  const extra1: string[] = [];
  const extra2: string[] = [];
  if (nationality) extra1.push(`Nationality: ${nationality}`);
  if (drivingLicense) extra1.push(`Driving License: ${drivingLicense}`);
  if (dateOfBirth && placeOfBirth) {
    extra1.push(`Born on ${dateOfBirth}, ${placeOfBirth}`);
  } else {
    if (dateOfBirth) extra1.push(`Date of Birth: ${dateOfBirth}`);
    if (placeOfBirth) extra1.push(`Place of Birth: ${placeOfBirth}`);
  }
  if (workRightEU && workRightUK) {
    extra2.push(`Right to work in EU and UK`);
  } else {
    if (workRightUK) extra2.push(`Right to work in UK`);
    if (workRightEU) extra2.push(`Right to work in EU`);
  }
  if (availability) extra2.push(`Availability: ${availability}`);

  return (
    <div
      style={{
        marginTop: '1rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        {extra1.map((extract, index) => {
          const isLast = index === extra1.length - 1;
          return (
            <div key={index}>
              <span>{extract}</span>
              {!isLast && <ResumePreviewVirgoDot />}
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        {extra2.map((extract, index) => {
          const isLast = index === extra2.length - 1;
          return (
            <div key={index}>
              <span>{extract}</span>
              {!isLast && <ResumePreviewVirgoDot />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const ResumePreviewVirgoSummary = ({
  children,
  paragraphTextAlignment,
  hasParagraphIndendation,
}: {
  children?: React.ReactNode;
  paragraphTextAlignment: 'justify' | 'right' | 'left';
  hasParagraphIndendation: boolean;
}) => {
  if (!children) return null;
  return (
    <>
      <ResumePreviewVirgoSection>
        <ResumePreviewVirgoSectionInner>Profile</ResumePreviewVirgoSectionInner>
      </ResumePreviewVirgoSection>
      <ResumePreviewSummaryContent
        $paragraphTextAlignment={paragraphTextAlignment}
        $hasParagraphIndendation={hasParagraphIndendation}
        dangerouslySetInnerHTML={{ __html: children }}
      ></ResumePreviewSummaryContent>
    </>
  );
};

export const ResumePreviewVirgoEmployment = ({ item }: { item: ResumeEmploymentStore }) => {
  return (
    <ResumePreviewBlock
      style={{
        marginBottom: '1rem',
      }}
    >
      <ResumePreviewVirgoBothSide>
        <div>
          <b>{item.position}</b>
        </div>
        <div
          style={{
            flex: '1',
            borderBottom: '1px dotted var(--design-text-color)',
            margin: '0 1rem',
          }}
        ></div>
        <div>
          <b>
            {item.startDate}
            {item.startDate && item.endDate && ' - '}
            {item.endDate}
          </b>
        </div>
      </ResumePreviewVirgoBothSide>
      <ResumePreviewVirgoBothSide>
        <ResumePreviewSmall>{item.company}</ResumePreviewSmall>
        <ResumePreviewSmall>{item.location}</ResumePreviewSmall>
      </ResumePreviewVirgoBothSide>
      <ResumePreviewSummaryContent
        $paragraphTextAlignment={'left'}
        $hasParagraphIndendation={false}
        dangerouslySetInnerHTML={{ __html: item?.highlights }}
      ></ResumePreviewSummaryContent>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewVirgoProject = ({ item }: { item: ResumeProjectStore }) => {
  return (
    <ResumePreviewBlock>
      <ResumePreviewVirgoBothSide>
        <div>
          <b>{item.name}</b>
        </div>
        <div
          style={{
            flex: '1',
            borderBottom: '1px dotted var(--design-text-color)',
            margin: '0 1rem',
          }}
        ></div>
        <div>
          <b>{item.date}</b>
        </div>
      </ResumePreviewVirgoBothSide>
      <div>
        <ResumePreviewSmall
          style={{
            color: 'var(--design-primary-color)',
          }}
        >
          {item.url}
        </ResumePreviewSmall>
      </div>
      <ResumePreviewSummaryContent
        $paragraphTextAlignment={'left'}
        $hasParagraphIndendation={false}
        dangerouslySetInnerHTML={{ __html: item?.highlights }}
      ></ResumePreviewSummaryContent>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewVirgoEducation = ({ item }: { item: ResumeEducationStore }) => {
  return (
    <ResumePreviewBlock
      style={{
        marginBottom: '1rem',
      }}
    >
      <ResumePreviewVirgoBothSide>
        <b>{item.institution}</b>
        <div
          style={{
            flex: '1',
            borderBottom: '1px dotted var(--design-text-color)',
            margin: '0 1rem',
          }}
        ></div>
        <div>
          <b>
            {item.startDate}
            {item.startDate && item.endDate && ' - '}
            {item.endDate}
          </b>
        </div>
      </ResumePreviewVirgoBothSide>
      <div>
        <ResumePreviewVirgoInline>
          <ResumePreviewSmall>{item.studyType}</ResumePreviewSmall>
          {item.area && <ResumePreviewSmall> in {item.area}</ResumePreviewSmall>}
          {item.score && (
            <ResumePreviewSmall>
              <i>Score: {item.score}</i>
            </ResumePreviewSmall>
          )}
        </ResumePreviewVirgoInline>
        <ResumePreviewSmall>{item.location}</ResumePreviewSmall>
      </div>
      <ResumePreviewSummaryContent
        $paragraphTextAlignment={'left'}
        $hasParagraphIndendation={false}
        dangerouslySetInnerHTML={{ __html: item?.highlights }}
      ></ResumePreviewSummaryContent>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewVirgoCertifications = ({
  title,
  items,
}: {
  title: string;
  items: ResumeCertificationStore[];
}) => {
  return (
    <>
      <ResumePreviewVirgoSection>
        <ResumePreviewVirgoSectionInner>{title}</ResumePreviewVirgoSectionInner>
      </ResumePreviewVirgoSection>
      {items.map((item, index) => {
        return (
          <ResumePreviewBlock key={index}>
            <ResumePreviewVirgoBothSide>
              <div>
                <b>{item.title}</b>
              </div>
              <div
                style={{
                  flex: '1',
                  borderBottom: '1px dotted var(--design-text-color)',
                  margin: '0 1rem',
                }}
              ></div>
              <div>
                <b>{item.date}</b>
              </div>
            </ResumePreviewVirgoBothSide>
            <ResumePreviewVirgoBothSide>
              <ResumePreviewSmall
                style={{
                  color: 'var(--design-primary-color)',
                }}
              >
                {item.url}
              </ResumePreviewSmall>
              <span>{item.issuer}</span>
            </ResumePreviewVirgoBothSide>
          </ResumePreviewBlock>
        );
      })}
    </>
  );
};

export const ResumePreviewVirgoLanguages = ({ title, items }: { title: string; items: ResumeLanguageStore[] }) => {
  return (
    <>
      <ResumePreviewVirgoSection>
        <ResumePreviewVirgoSectionInner>{title}</ResumePreviewVirgoSectionInner>
      </ResumePreviewVirgoSection>
      <ResumePreviewVirgoInline>
        {items.map((item, index) => {
          const { language, proficiency } = item;
          const isLast = index === items.length - 1;
          return (
            <div key={index}>
              <div>
                <span>{language}</span> <span>[{getProficiencyLabel(proficiency)}]</span>
                {isLast ? null : <span>, </span>}
              </div>
            </div>
          );
        })}
      </ResumePreviewVirgoInline>
    </>
  );
};

export const ResumePreviewVirgoHobby = ({ title, content }: { title: string; content: string }) => {
  return (
    <>
      <ResumePreviewVirgoSection>
        <ResumePreviewVirgoSectionInner>{title}</ResumePreviewVirgoSectionInner>
      </ResumePreviewVirgoSection>
      <ResumePreviewHtml content={content} />
    </>
  );
};

export const ResumePreviewVirgoSkills = ({ title, items }: { title: string; items: ResumeSkillStore[] }) => {
  return (
    <>
      <ResumePreviewVirgoSection>
        <ResumePreviewVirgoSectionInner>{title} </ResumePreviewVirgoSectionInner>
      </ResumePreviewVirgoSection>
      <ResumePreviewVirgoInline>
        {items.map((item, index) => {
          const { name, level } = item;
          const levelLabel = getLevelLabel(level);
          return (
            <ResumePreviewVirgoInline key={index}>
              <b>{name}</b>:<span>{levelLabel}</span>
            </ResumePreviewVirgoInline>
          );
        })}
      </ResumePreviewVirgoInline>
    </>
  );
};

export const ResumePreviewVirgoReferences = ({
  title,
  items,
  onDemand,
}: {
  title: string;
  items: ResumeReferenceStore[];
  onDemand?: boolean;
}) => {
  return (
    <>
      <ResumePreviewVirgoSection>
        <ResumePreviewVirgoSectionInner>{title}</ResumePreviewVirgoSectionInner>
      </ResumePreviewVirgoSection>
      {onDemand ? (
        <b>References available on demand</b>
      ) : (
        items.map((item, index) => {
          const { name, email, phone, company, linkedIn } = item;
          return (
            <table
              key={index}
              style={{
                width: '100%',
                marginBottom: '1rem',
                borderCollapse: 'collapse',
                margin: '1rem 0',
              }}
            >
              <tr>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                  }}
                >
                  {name}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                  }}
                >
                  {company}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                  }}
                >
                  {email}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                  }}
                >
                  {phone}
                </td>
              </tr>
              <tr>
                <td
                  colspan={2}
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                    textAlign: 'center',
                  }}
                >
                  LinkedIn:
                  <span
                    style={{
                      color: 'var(--design-primary-color)',
                    }}
                  >
                    {' '}
                    : {linkedIn}
                  </span>
                </td>
              </tr>
            </table>
          );
        })
      )}
      <Spacer y={2} />
    </>
  );
};

export const ResumePreviewVirgoLinks = ({ title, items }: { title: string; items: ResumeLinkStore[] }) => {
  return (
    <>
      <ResumePreviewVirgoSection>
        <ResumePreviewVirgoSectionInner>{title}</ResumePreviewVirgoSectionInner>
      </ResumePreviewVirgoSection>
      <ResumePreviewVirgoInline>
        {items.map((item, index) => {
          const { text, url } = item;
          return (
            <ResumePreviewVirgoInline key={index}>
              <b>{text}</b>:<span>{url}</span>
            </ResumePreviewVirgoInline>
          );
        })}
      </ResumePreviewVirgoInline>
    </>
  );
};

export const ResumePreviewVirgoCustom = ({ item }: { item: ResumeCustomStore }) => {
  const { header, subheader, highlights } = item;
  return (
    <ResumePreviewBlock>
      <ResumePreviewLarge>{header}</ResumePreviewLarge>
      <ResumePreviewlarge>{subheader}</ResumePreviewlarge>
      <>
        {highlights?.map((highlight, index) => {
          return <ResumePreviewHtml key={index} content={highlight as unknown as string} />;
        })}
      </>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewVirgoPageBreak = styled.div``;
ResumePreviewVirgoPageBreak.displayName = 'PageBreak';

export const ResumePreviewVirgoSection = styled.div`
  background-color: var(--design-primary-color);
  color: var(--design-contrast-color);
  padding: 4pt;
  text-transform: uppercase;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`;

export const ResumePreviewVirgoSectionInner = styled.div`
  width: fit-content;
  text-align: center;
  border-bottom: 1px solid var(--design-contrast-color);
`;

/* Custom styles */
const ResumePreviewVirgoDot = styled.span`
  display: inline-block;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;
  height: 5px;

  &:after {
    content: '';
    position: absolute;
    font-size: 1.6rem;
    height: 2px;
    width: 2px;
    background-color: var(--design-primary-color);
    border-radius: 50%;
  }
`;

const ResumePreviewVirgoInline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1em;
`;

const ResumePreviewVirgoBothSide = styled.div`
  display: flex;
  justify-content: space-between;
`;
