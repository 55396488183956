export function getLevelLabel(level?: number) {
  switch (level) {
    case 0:
      return 'Not Applicable';
    case 1:
      return 'Novice';
    case 2:
      return 'Beginner';
    case 3:
      return 'Skillful';
    case 4:
      return 'Experienced';
    case 5:
      return 'Expert';
    default:
      return 'Not Applicable';
  }
}


export function getProficiencyLabel(level?: number) {
  switch (level) {
    case 0:
      return 'Not Applicable';
    case 1:
      return 'Novice';
    case 2:
      return 'Beginner';
    case 3:
      return 'Skillful';
    case 4:
      return 'Experienced';
    case 5:
      return 'Expert';
    default:
      return 'Not Applicable';
  }
}
