import styled from 'styled-components';

import { Spacer } from '../../atoms/Spacer/Spacer';
import {
  ResumeBasicStore,
  ResumeCertificationStore,
  ResumeCustomStore,
  ResumeEducationStore,
  ResumeEmploymentStore,
  ResumeLanguageStore,
  ResumeLinkStore,
  ResumeProjectStore,
  ResumeReferenceStore,
  ResumeSkillStore,
} from '../../../routes/Resume/Store';
import { getLevelLabel, getProficiencyLabel } from './helper';
import {
  ResumePreviewBlock,
  ResumePreviewHUGE,
  ResumePreviewHtml,
  ResumePreviewLARGE,
  ResumePreviewLarge,
  ResumePreviewRight,
  ResumePreviewSummaryContent,
  ResumePreviewlarge,
} from './ResumePreview.styles';

export const ResumePreviewTaurusBasic = ({ name, jobTitle }: { name?: string; jobTitle?: string }) => {
  return (
    <ResumePreviewRight>
      {name ? (
        <ResumePreviewHUGE
          style={{
            color: 'var(--design-primary-color)',
            fontWeight: 'bold',
            lineHeight: '1.4em',
            transform: 'translateY(-0.2em)',
          }}
        >
          {name}
        </ResumePreviewHUGE>
      ) : null}
      {jobTitle ? (
        <ResumePreviewLARGE
          style={{
            color: 'var(--design-secondary-color)',
            transform: 'translateY(-0.2em)',
            fontSize: '1.6red',
          }}
        >
          <i>{jobTitle}</i>
        </ResumePreviewLARGE>
      ) : null}
      <Spacer y={24} />
    </ResumePreviewRight>
  );
};

export const ResumePreviewTaurusContact = ({
  email,
  phone,
  fullAddress,
  linkedinUrl,
  githubUrl,
}: ResumePreviewTaurusContactProps) => {
  const contacts = [];
  if (email) contacts.push(email);
  if (phone) contacts.push(phone);
  if (fullAddress) contacts.push(fullAddress);
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {contacts.map((contact, index) => {
          const isLast = index === contacts.length - 1;
          return (
            <div key={index}>
              <b>{contact}</b>
              {!isLast && <ResumePreviewTaurusDot />}
            </div>
          );
        })}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {linkedinUrl ? <span>{linkedinUrl}</span> : null}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {githubUrl ? <span>{githubUrl}</span> : null}
      </div>
      <Spacer y={24} />
    </>
  );
};

type ResumePreviewTaurusContactProps = {
  email?: string;
  phone?: string;
  fullAddress?: string;
  linkedinUrl?: string;
  githubUrl?: string;
};

export const ResumePreviewTaurusExtra = (basic: ResumeBasicStore) => {
  const { nationality, drivingLicense, dateOfBirth, placeOfBirth, workRightUK, workRightEU, availability } = basic;
  const extra1: string[] = [];
  const extra2: string[] = [];
  if (nationality) extra1.push(`Nationality: ${nationality}`);
  if (drivingLicense) extra1.push(`Driving License: ${drivingLicense}`);
  if (dateOfBirth && placeOfBirth) {
    extra1.push(`Born on ${dateOfBirth}, ${placeOfBirth}`);
  } else {
    if (dateOfBirth) extra1.push(`Date of Birth: ${dateOfBirth}`);
    if (placeOfBirth) extra1.push(`Place of Birth: ${placeOfBirth}`);
  }
  if (workRightEU && workRightUK) {
    extra2.push(`Right to work in EU and UK`);
  } else {
    if (workRightUK) extra2.push(`Right to work in UK`);
    if (workRightEU) extra2.push(`Right to work in EU`);
  }
  if (availability) extra2.push(`Availability: ${availability}`);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {extra1.map((extract, index) => {
          const isLast = index === extra1.length - 1;
          return (
            <div key={index}>
              <span>{extract}</span>
              {!isLast && <ResumePreviewTaurusDot />}
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginBottom: '.7rem',
        }}
      >
        {extra2.map((extract, index) => {
          const isLast = index === extra2.length - 1;
          return (
            <div key={index}>
              <span>{extract}</span>
              {!isLast && <ResumePreviewTaurusDot />}
            </div>
          );
        })}
      </div>
    </>
  );
};

export const ResumePreviewTaurusSummary = ({
  children,
  paragraphTextAlignment,
  hasParagraphIndendation,
}: {
  children?: React.ReactNode;
  paragraphTextAlignment: 'justify' | 'right' | 'left';
  hasParagraphIndendation: boolean;
}) => {
  if (!children) return null;
  return (
    <ResumePreviewSummaryContent
      $paragraphTextAlignment={paragraphTextAlignment}
      $hasParagraphIndendation={hasParagraphIndendation}
      dangerouslySetInnerHTML={{ __html: children }}
    ></ResumePreviewSummaryContent>
  );
};

export const ResumePreviewTaurusEmployment = ({ item }: { item: ResumeEmploymentStore }) => {
  return (
    <ResumePreviewBlock
      style={{
        marginTop: '.5em',
        marginBottom: '.5em',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <b>{item.company}</b>
        </div>
        <div
          style={{
            backgroundColor: 'var(--design-primary-color)',
            color: 'var(--design-contrast-color)',
            height: '1.4rem',
            width: '37%',
            textAlign: 'right',
            padding: '0.2rem 0.5em',
          }}
        >
          {item.startDate} - {item.endDate}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {item.location && <i>{item.location}, </i>}
          <i>{item.position}</i>
        </div>
        <div></div>
      </div>
      <ResumePreviewSummaryContent
        $paragraphTextAlignment={'left'}
        $hasParagraphIndendation={false}
        dangerouslySetInnerHTML={{ __html: item?.highlights }}
      ></ResumePreviewSummaryContent>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewTaurusEducation = ({ item }: { item: ResumeEducationStore }) => {
  return (
    <ResumePreviewBlock>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <div>
            <b>{item.institution}</b>
          </div>
          <div>
            {item.studyType}
            {item.area && <> in {item.area}</>}
            {item.score && <i> Score: {item.score}</i>}
          </div>
        </div>
        <div
          style={{
            backgroundColor: 'var(--design-primary-color)',
            color: 'var(--design-contrast-color)',
            height: '16px',
            width: '28%',
            textAlign: 'right',
            padding: '0 0.5em',
          }}
        >
          {item.startDate} - {item.endDate}
        </div>
      </div>
      <ResumePreviewSummaryContent
        $paragraphTextAlignment={'right'}
        $hasParagraphIndendation={false}
        dangerouslySetInnerHTML={{ __html: item?.highlights }}
      ></ResumePreviewSummaryContent>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewTaurusProject = ({ item }: { item: ResumeProjectStore }) => {
  return (
    <ResumePreviewBlock>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <div>
            <b>{item.name}</b>
          </div>
          <div>{item.url}</div>
        </div>
        <div
          style={{
            backgroundColor: 'var(--design-primary-color)',
            color: 'var(--design-contrast-color)',
            height: '16px',
            width: '28%',
            textAlign: 'right',
            padding: '0 0.5em',
          }}
        >
          {item.date}
        </div>
      </div>
      <ResumePreviewSummaryContent
        $paragraphTextAlignment={'right'}
        $hasParagraphIndendation={false}
        dangerouslySetInnerHTML={{ __html: item?.highlights }}
      ></ResumePreviewSummaryContent>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewTaurusCertifications = ({
  title,
  items,
}: {
  title: string;
  items: ResumeCertificationStore[];
}) => {
  return (
    <>
      <ResumePreviewTaurusSection>{title}</ResumePreviewTaurusSection>
      {items.map((item, index) => {
        return (
          <ResumePreviewBlock
            style={{
              marginTop: '.5em',
              marginBottom: '.5em',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <b>{item.title}</b>
              </div>
              <div
                style={{
                  backgroundColor: 'var(--design-primary-color)',
                  color: 'var(--design-contrast-color)',
                  height: '1.4rem',
                  width: '37%',
                  textAlign: 'right',
                  padding: '0.2rem 0.5em',
                }}
              >
                {item.date}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                {item.issuer && <i>{item.issuer} </i>}
                {item.issuer && item.url && <i>: </i>}
                <i>{item.url}</i>
              </div>
              <div></div>
            </div>
            <ResumePreviewSummaryContent
              $paragraphTextAlignment={'left'}
              $hasParagraphIndendation={false}
              dangerouslySetInnerHTML={{ __html: item?.highlights }}
            ></ResumePreviewSummaryContent>
          </ResumePreviewBlock>
        );
      })}
    </>
  );
};

export const ResumePreviewTaurusLanguages = ({ title, items }: { title: string; items: ResumeLanguageStore[] }) => {
  return (
    <>
      <ResumePreviewTaurusSection>{title}</ResumePreviewTaurusSection>
      <ResumePreviewTaurusInline>
        {items.map((item, index) => {
          const { language, proficiency } = item;
          const isLast = index === items.length - 1;
          return (
            <div key={index}>
              <div>
                <b>{language}</b> <span>[{getProficiencyLabel(proficiency)}]</span>
                {isLast ? null : <span>, </span>}
              </div>
            </div>
          );
        })}
      </ResumePreviewTaurusInline>
    </>
  );
};

export const ResumePreviewTaurusHobby = ({ title, content }: { title: string; content: string }) => {
  return (
    <>
      <ResumePreviewTaurusSection>{title}</ResumePreviewTaurusSection>
      <ResumePreviewHtml content={content} />
    </>
  );
};

export const ResumePreviewTaurusSkills = ({ title, items }: { title: string; items: ResumeSkillStore[] }) => {
  return (
    <>
      <ResumePreviewTaurusSection>{title}</ResumePreviewTaurusSection>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          gap: '0em',
        }}
      >
        {items.map((item, index) => {
          const { name, level } = item;
          const levelLabel = getLevelLabel(level);
          return (
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', padding: '0.1rem' }} key={index}>
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {name}
              </div>
              <div>{levelLabel}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export const ResumePreviewTaurusReferences = ({
  title,
  items,
  onDemand,
}: {
  title: string;
  items: ResumeReferenceStore[];
  onDemand?: boolean;
}) => {
  return (
    <>
      <ResumePreviewTaurusSection>{title}</ResumePreviewTaurusSection>
      {onDemand ? (
        <b>References available on demand</b>
      ) : (
        items.map((item, index) => {
          const { name, email, phone, company, linkedIn } = item;
          return (
            <table
              key={index}
              style={{
                width: '100%',
                marginBottom: '1rem',
                borderCollapse: 'collapse',
                margin: '1rem 0',
              }}
            >
              <tr>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                  }}
                >
                  {name}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                  }}
                >
                  {company}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                  }}
                >
                  {email}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                  }}
                >
                  {phone}
                </td>
              </tr>
              <tr>
                <td
                  colspan={2}
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                    textAlign: 'center',
                  }}
                >
                  LinkedIn:
                  <span
                    style={{
                      color: 'var(--design-primary-color)',
                    }}
                  >
                    {' '}
                    : {linkedIn}
                  </span>
                </td>
              </tr>
            </table>
          );
        })
      )}
      <Spacer y={2} />
    </>
  );
};

export const ResumePreviewTaurusLinks = ({ title, items }: { title: string; items: ResumeLinkStore[] }) => {
  return (
    <>
      <ResumePreviewTaurusSection>{title}</ResumePreviewTaurusSection>
      <ResumePreviewTaurusInline>
        {items.map((item, index) => {
          const { text, url } = item;
          return (
            <ResumePreviewTaurusInline key={index}>
              <b>{text}</b>:<span>{url}</span>
            </ResumePreviewTaurusInline>
          );
        })}
      </ResumePreviewTaurusInline>
    </>
  );
};

export const ResumePreviewTaurusCustom = ({ item }: { item: ResumeCustomStore }) => {
  const { header, subheader, highlights } = item;
  return (
    <ResumePreviewBlock>
      <ResumePreviewLarge>{header}</ResumePreviewLarge>
      <ResumePreviewlarge>{subheader}</ResumePreviewlarge>
      <>
        {highlights?.map((highlight, index) => {
          return <ResumePreviewHtml key={index} content={highlight as unknown as string} />;
        })}
      </>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewTaurusPageBreak = styled.div``;
ResumePreviewTaurusPageBreak.displayName = 'PageBreak';

export const ResumePreviewTaurusSection = styled.div`
  border-bottom: 3px solid var(--design-text-color);
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 1.1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: bolder;
  letter-spacing: -1px;
`;

/* Custom styles */
const ResumePreviewTaurusDot = styled.span`
  border-left: 1px solid black;
  display: inline-block;
  align-items: center;
  justify-content: center;
  height: 8px;
  width: 8px;
  margin: 0 0.25em 0 0.5em;
`;

const ResumePreviewTaurusInline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1em;
`;

const ResumePreviewTaurusBothSide = styled.div`
  display: flex;
  justify-content: space-between;
`;
