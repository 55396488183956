import styled from 'styled-components';

import {
  ResumeBasicStore,
  ResumeCertificationStore,
  ResumeCustomStore,
  ResumeEducationStore,
  ResumeEmploymentStore,
  ResumeLanguageStore,
  ResumeLinkStore,
  ResumeProjectStore,
  ResumeReferenceStore,
  ResumeSkillStore,
} from '../../../routes/Resume/Store';
import { getLevelLabel, getProficiencyLabel } from './helper';
import {
  ResumePreviewBlock,
  ResumePreviewCenter,
  ResumePreviewHtml,
  ResumePreviewHuge,
  ResumePreviewLARGE,
  ResumePreviewLarge,
  ResumePreviewSummaryContent,
  ResumePreviewlarge,
} from './ResumePreview.styles';

export const ResumePreviewAriesBasic = ({ name, jobTitle }: ResumePreviewAriesBasicProps) => {
  return (
    <ResumePreviewCenter>
      {name ? <ResumePreviewHuge>{name}</ResumePreviewHuge> : null}
      {jobTitle ? <ResumePreviewLARGE>{jobTitle}</ResumePreviewLARGE> : null}
      <div
        style={{
          height: '1rem',
        }}
      ></div>
    </ResumePreviewCenter>
  );
};

type ResumePreviewAriesBasicProps = {
  name?: string;
  jobTitle?: string;
};

export const ResumePreviewAriesContact = ({
  email,
  phone,
  fullAddress,
  linkedinUrl,
  githubUrl,
}: ResumePreviewAriesContactProps) => {
  const contacts: string[] = [];
  if (email) contacts.push(email);
  if (phone) contacts.push(phone);
  if (fullAddress) contacts.push(fullAddress);

  return (
    <ResumePreviewCenter>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {contacts.map((contact, index) => {
          const isLast = index === contacts.length - 1;
          return (
            <div key={index}>
              <b>{contact}</b>
              {!isLast && <ResumePreviewAriesDot />}
            </div>
          );
        })}
      </div>
      <div>{linkedinUrl ? <span>{linkedinUrl}</span> : null}</div>
      <div>{githubUrl ? <span>{githubUrl}</span> : null}</div>
    </ResumePreviewCenter>
  );
};

type ResumePreviewAriesContactProps = {
  email?: string;
  phone?: string;
  fullAddress?: string;
  linkedinUrl?: string;
  githubUrl?: string;
};

export const ResumePreviewAriesExtra = (basic: ResumeBasicStore) => {
  const { nationality, drivingLicense, dateOfBirth, placeOfBirth, workRightUK, workRightEU, availability } = basic;
  const extra1: string[] = [];
  const extra2: string[] = [];
  if (nationality) extra1.push(`Nationality: ${nationality}`);
  if (drivingLicense) extra1.push(`Driving License: ${drivingLicense}`);
  if (dateOfBirth && placeOfBirth) {
    extra1.push(`Born on ${dateOfBirth}, ${placeOfBirth}`);
  } else {
    if (dateOfBirth) extra1.push(`Date of Birth: ${dateOfBirth}`);
    if (placeOfBirth) extra1.push(`Place of Birth: ${placeOfBirth}`);
  }
  if (workRightEU && workRightUK) {
    extra2.push(`Right to work in EU and UK`);
  } else {
    if (workRightUK) extra2.push(`Right to work in UK`);
    if (workRightEU) extra2.push(`Right to work in EU`);
  }
  if (availability) extra2.push(`Availability: ${availability}`);

  return (
    <ResumePreviewCenter>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {extra1.map((extract, index) => {
          const isLast = index === extra1.length - 1;
          return (
            <div key={index}>
              <span>{extract}</span>
              {!isLast && <ResumePreviewAriesDot />}
            </div>
          );
        })}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {extra2.map((extract, index) => {
          const isLast = index === extra2.length - 1;
          return (
            <div key={index}>
              <span>{extract}</span>
              {!isLast && <ResumePreviewAriesDot />}
            </div>
          );
        })}
      </div>
      <div
        style={{
          height: '.7rem',
        }}
      ></div>
    </ResumePreviewCenter>
  );
};

export const ResumePreviewAriesSummary = ({
  children,
  paragraphTextAlignment,
  hasParagraphIndendation,
}: {
  children?: React.ReactNode;
  paragraphTextAlignment: 'justify' | 'right' | 'left';
  hasParagraphIndendation: boolean;
}) => {
  if (!children) return null;
  return (
    <ResumePreviewSummaryContent
      $paragraphTextAlignment={paragraphTextAlignment}
      $hasParagraphIndendation={hasParagraphIndendation}
      dangerouslySetInnerHTML={{ __html: children }}
    ></ResumePreviewSummaryContent>
  );
};

export const ResumePreviewAriesEmployment = ({ item }: { item: ResumeEmploymentStore }) => {
  return (
    <div style={{ marginBottom: '1rem' }}>
      <ResumePreviewAriesBothSide
        style={{
          marginBottom: '.2rem',
        }}
      >
        <b>{item.company}</b>
        <div>{item.location}</div>
      </ResumePreviewAriesBothSide>
      <ResumePreviewAriesBothSide
        style={{
          marginBottom: '1rem',
        }}
      >
        <i>{item.position}</i>
        <div>
          {item.startDate} - {item.endDate}
        </div>
      </ResumePreviewAriesBothSide>
      <ResumePreviewSummaryContent
        $paragraphTextAlignment={'left'}
        $hasParagraphIndendation={false}
        dangerouslySetInnerHTML={{ __html: item?.highlights }}
      ></ResumePreviewSummaryContent>
    </div>
  );
};

export const ResumePreviewAriesProject = ({ item }: { item: ResumeProjectStore }) => {
  return (
    <ResumePreviewBlock>
      <ResumePreviewAriesBothSide>
        <b>{item.name}</b>
        <span>{item.date}</span>
      </ResumePreviewAriesBothSide>
      <ResumePreviewAriesBothSide>
        <span>{item.url}</span>
      </ResumePreviewAriesBothSide>
      <ResumePreviewSummaryContent
        $paragraphTextAlignment={'right'}
        $hasParagraphIndendation={false}
        dangerouslySetInnerHTML={{ __html: item?.highlights }}
      ></ResumePreviewSummaryContent>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewAriesEducation = ({ item }: { item: ResumeEducationStore }) => {
  return (
    <ResumePreviewBlock>
      <ResumePreviewAriesBothSide>
        <b>{item.institution}</b>
        <div>{item.location}</div>
      </ResumePreviewAriesBothSide>
      <ResumePreviewAriesBothSide>
        <div>
          {item.studyType}
          {item.area && <>in {item.area}</>}
          {item.score && <i> Score: {item.score}</i>}
        </div>
        <div>
          {item.startDate} - {item.endDate}
        </div>
      </ResumePreviewAriesBothSide>
      <ResumePreviewSummaryContent
        $paragraphTextAlignment={'right'}
        $hasParagraphIndendation={false}
        dangerouslySetInnerHTML={{ __html: item?.highlights }}
      ></ResumePreviewSummaryContent>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewAriesCertifications = ({
  title,
  items,
}: {
  title: string;
  items: ResumeCertificationStore[];
}) => {
  return (
    <>
      <ResumePreviewAriesSection>{title}</ResumePreviewAriesSection>
      {items.map((item, index) => {
        return (
          <div
            style={{
              marginBottom: '1rem',
            }}
            key={index}
          >
            <ResumePreviewAriesBothSide>
              <b>{item.title}</b>
              <span>{item.issuer}</span>
            </ResumePreviewAriesBothSide>
            <ResumePreviewAriesBothSide>
              <span>{item.date}</span>
              <span>{item.url}</span>
            </ResumePreviewAriesBothSide>
          </div>
        );
      })}
    </>
  );
};

export const ResumePreviewAriesLanguages = ({ title, items }: { title: string; items: ResumeLanguageStore[] }) => {
  return (
    <>
      <ResumePreviewAriesSection>{title}</ResumePreviewAriesSection>
      <div
        style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '.2rem', justifyContent: 'flex-start' }}
      >
        {items.map((item, index) => {
          const { language, proficiency } = item;
          return (
            <div key={index}>
              <b>{language}</b> [<span>{getProficiencyLabel(proficiency)}]</span>
              {index !== items.length - 1 && ', '}
            </div>
          );
        })}
      </div>
    </>
  );
};

export const ResumePreviewAriesHobby = ({ title, content }: { title: string; content: string }) => {
  return (
    <>
      <ResumePreviewAriesSection>{title}</ResumePreviewAriesSection>
      <ResumePreviewHtml content={content} />
    </>
  );
};

export const ResumePreviewAriesSkills = ({ title, items }: { title: string; items: ResumeSkillStore[] }) => {
  return (
    <>
      <ResumePreviewAriesSection>{title}</ResumePreviewAriesSection>
      <div
        style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '.2rem', justifyContent: 'flex-start' }}
      >
        {items.map((item, index) => {
          const { name, level } = item;
          const levelLabel = getLevelLabel(level);
          return (
            <div key={index}>
              <b>{name}</b> [<span>{levelLabel}]</span>
              {index !== items.length - 1 && ', '}
            </div>
          );
        })}
      </div>
    </>
  );
};

export const ResumePreviewAriesReferences = ({
  title,
  items,
  onDemand,
}: {
  title: string;
  items: ResumeReferenceStore[];
  onDemand?: boolean;
}) => {
  return (
    <>
      <ResumePreviewAriesSection>{title}</ResumePreviewAriesSection>
      {onDemand ? (
        <b>References available on demand</b>
      ) : (
        items.map((item, index) => {
          const { name, email, phone, company, linkedIn } = item;
          return (
            <table
              key={index}
              style={{
                width: '100%',
                marginBottom: '1rem',
                borderCollapse: 'collapse',
                margin: '1rem 0',
              }}
            >
              <tr>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                  }}
                >
                  {name}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                  }}
                >
                  {company}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                  }}
                >
                  {email}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                  }}
                >
                  {phone}
                </td>
              </tr>
              <tr>
                <td
                  colspan={2}
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                    textAlign: 'center',
                  }}
                >
                  LinkedIn:
                  <span
                    style={{
                      color: 'var(--design-primary-color)',
                    }}
                  >
                    {' '}
                    : {linkedIn}
                  </span>
                </td>
              </tr>
            </table>
          );
        })
      )}
    </>
  );
};

export const ResumePreviewAriesLinks = ({ title, items }: { title: string; items: ResumeLinkStore[] }) => {
  return (
    <>
      <ResumePreviewAriesSection>{title}</ResumePreviewAriesSection>
      <ResumePreviewAriesInline>
        {items.map((item, index) => {
          const { text, url } = item;
          return (
            <ResumePreviewAriesInline key={index}>
              <b>{text}</b>:<span>{url}</span>
            </ResumePreviewAriesInline>
          );
        })}
      </ResumePreviewAriesInline>
    </>
  );
};

export const ResumePreviewAriesCustom = ({ item }: { item: ResumeCustomStore }) => {
  const { header, subheader, highlights } = item;
  return (
    <ResumePreviewBlock>
      <ResumePreviewLarge>{header}</ResumePreviewLarge>
      <ResumePreviewlarge>{subheader}</ResumePreviewlarge>
      {/* <pre>{JSON.stringify(highlights, null, 2)}</pre> */}
      <>
        {highlights?.map((highlight, index) => {
          return <ResumePreviewHtml key={index} content={highlight as unknown as string} />;
        })}
      </>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewAriesColoredBlock = styled.div`
  margin: 1.5rem auto 1.5rem;
  width: 100%;
  background-color: var(--design-primary-color);
  height: 6px;
`;

export const ResumePreviewAriesPageBreak = styled.div``;
ResumePreviewAriesPageBreak.displayName = 'PageBreak';

export const ResumePreviewAriesSection = styled.div`
  border-bottom: 1px solid var(--design-primary-color);
  padding-bottom: 2pt;
  text-transform: uppercase;
  margin-top: 8pt;
  margin-bottom: 4pt;
  font-size: 1.4rem;
  color: var(--design-header-color);
`;

/* Custom styles */
const ResumePreviewAriesDot = styled.span`
  display: inline-block;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;
  height: 5px;

  &:after {
    content: '';
    position: absolute;
    font-size: 1.6rem;
    height: 2px;
    width: 2px;
    background-color: var(--design-primary-color);
    border-radius: 50%;
  }
`;

const ResumePreviewAriesInline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const ResumePreviewAriesBothSide = styled.div`
  display: flex;
  justify-content: space-between;
`;
