import styled from 'styled-components';

import { Colors } from '../../../styles/colors';

export const Container = styled.div`
  background-color: var(--design-background-color);
  color: var(--design-text-color);
  font-family: var(--design-font-family);
  font-size: var(--design-font-size);
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 29.7cm;
`;

export const InnerContainer = styled.div<{ $page: number }>`
  position: relative;
  top: ${({ $page }) => $page * -1155}px; // 1122 + 32
`;

export const PagesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  background-color: ${Colors.GreyLightest};
`;

export const Page = styled.div`
  width: 21cm;
  height: 29.7cm;
  padding: 17.5mm 15mm 17.5mm 15mm;
  min-width: 0;
  background-color: var(--design-background-color);
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const PageHeader = styled.div``;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PageSidebar = styled.div`
  width: 34%;
`;

export const PageBody = styled.div`
  width: 65%;
`;

export const SectionContainer = styled.div`
  position: relative;
  /* outline: 1px solid black; */
`;

export const SectionSize = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${Colors.Black};
  color: ${Colors.White};
  font-size: 8px;
`;

export const PageNavigator = styled.div`
  position: absolute;
  bottom: 32px;
  right: 16px;
  width: fit-content;
  background-color: ${Colors.GreyDarkest};
  border-radius: 12px;
  font-size: 32px;
  padding: 16px 16px;
  color: ${Colors.GreyLight};
  font-family: 'Roboto', sans-serif;

  button {
    padding: 0;
    background-color: transparent;
    border: none;
    font-size: 24px;
  }

  svg {
    cursor: pointer;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const PageNavigatorActive = styled.span`
  color: white;
`;

export const ResumePreviewSummaryContent = styled.div<{
  $hasParagraphIndendation: boolean;
  $paragraphTextAlignment: 'right' | 'left' | 'justify';
}>`
  width: 100%;
  margin: 0 auto;
  p {
    text-align: justify;
    min-height: 14px;
    line-height: var(--design-line-height);
    margin: 0;
  }
  ul {
    li {
      list-style-type: none;
      margin: 1rem;
      padding: 0;
      display: flex;
      gap: 8px;
      &:before {
        content: '•';
        color: var(--design-primary-color);
        font-weight: bold;
        display: inline-block;
        font-size: 1.5em;
        margin-left: -1.5em;
      }
    }
  }
  ${(props) => {
    if (props.$paragraphTextAlignment === 'right') {
      return `
        p {
          text-align: right;
        }
      `;
    }
    if (props.$paragraphTextAlignment === 'left') {
      return `
        p {
          text-align: left;
        }
      `;
    }
    if (props.$paragraphTextAlignment === 'justify' && props.$hasParagraphIndendation) {
      return `
        p {
          text-indent: 24pt;
        }
      `;
    }
  }}
`;

export const ResumePreviewBlock = styled.div``;

export const ResumePreviewHtml = ({ content }: { content: string }) => {
  return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
};

export const ResumePreviewSmall = styled.div`
  display: flex;
  font-size: 0.9em;
  font-weight: normal;
`;

export const ResumePreviewNormal = styled.div`
  display: flex;
  font-size: 1em;
  font-weight: normal;
`;

export const ResumePreviewlarge = styled.div`
  display: flex;
  font-size: 1.1em;
  line-height: 1.1em;
  font-weight: normal;
`;

export const ResumePreviewLarge = styled.div`
  display: flex;
  font-size: 1.2em;
  line-height: 1.2em;
  font-weight: normal;
`;

export const ResumePreviewLARGE = styled.div`
  display: flex;
  font-size: 1.4em;
  line-height: 1.4em;
  font-weight: normal;
`;

export const ResumePreviewhuge = styled.div`
  display: flex;
  font-size: 1.7em;
  line-height: 1.7em;
  font-weight: normal;
`;

export const ResumePreviewHuge = styled.div`
  display: flex;
  font-size: 2em;
  line-height: 1em;
  font-weight: normal;
`;

export const ResumePreviewHUGE = styled.div`
  display: flex;
  font-size: 2.5em;
  line-height: 2.5em;
  font-weight: normal;
`;

export const ResumePreviewCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export const ResumePreviewLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
`;

export const ResumePreviewRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 auto;
`;

export const ResumesPreviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const ResumesPreviewLeftWrapper = styled.div`
  flex: 1;
  transform: translateX(50px);
  z-index: 1;
`;

export const ResumesPreviewCentralWrapper = styled.div`
  flex: 2;
  z-index: 2;
`;

export const ResumesPreviewRightWrapper = styled.div`
  flex: 1;
  transform: translateX(-50px);
  z-index: 1;
`;
