import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faPhone,
  faLocationDot,
  faBriefcase,
  faGraduationCap,
  faBicycle,
  faComment,
  faGlobe,
  faLink,
  faPuzzlePiece,
  faStar,
  faThumbsUp,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

import { Spacer } from '../../atoms/Spacer/Spacer';
import {
  ResumeBasicStore,
  ResumeCertificationStore,
  ResumeCustomStore,
  ResumeEducationStore,
  ResumeEmploymentStore,
  ResumeLanguageStore,
  ResumeLinkStore,
  ResumeProjectStore,
  ResumeReferenceStore,
  ResumeSkillStore,
} from '../../../routes/Resume/Store';
import { getLevelLabel, getProficiencyLabel } from './helper';
import {
  ResumePreviewBlock,
  ResumePreviewHtml,
  ResumePreviewLarge,
  ResumePreviewSmall,
  ResumePreviewSummaryContent,
  ResumePreviewlarge,
} from './ResumePreview.styles';

export const ResumePreviewLibraBasic = ({ firstName, lastName, jobTitle }: ResumePreviewLibraBasicProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
      }}
    >
      {firstName ? (
        <div
          style={{
            color: 'var(--design-primary-color)',
            fontWeight: 'bold',
            lineHeight: '3rem',
            fontSize: '46px',
            marginBottom: '0.2em',
          }}
        >
          {firstName}
        </div>
      ) : null}
      {lastName ? (
        <div
          style={{
            color: 'var(--design-primary-color)',
            fontWeight: 'bold',
            lineHeight: '3rem',
            fontSize: '46px',
            marginBottom: '0.2em',
          }}
        >
          {lastName}
        </div>
      ) : null}
      {jobTitle ? (
        <div
          style={{
            color: 'var(--design-secondary-color)',
            fontSize: '16px',
          }}
        >
          <i>{jobTitle}</i>
        </div>
      ) : null}
    </div>
  );
};

type ResumePreviewLibraBasicProps = {
  name?: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
};

export const ResumePreviewLibraContact = ({
  email,
  phone,
  fullAddress,
  linkedinUrl,
  githubUrl,
}: ResumePreviewLibraContactProps) => {
  const linkedinPath = linkedinUrl ? linkedinUrl.split('/').pop() : '';
  const githubPath = githubUrl ? githubUrl.split('/').pop() : '';
  return (
    <ResumePreviewBlock>
      {email && (
        <>
          <ResumePreviewLibraInline
            style={{
              fontSize: '1rem',
            }}
          >
            <ResumePreviewLibraIcon icon={faEnvelope} />
            <b>{email}</b>
          </ResumePreviewLibraInline>
          <Spacer y={4} />
        </>
      )}
      {phone && (
        <>
          <ResumePreviewLibraInline
            style={{
              fontSize: '1rem',
            }}
          >
            <ResumePreviewLibraIcon icon={faPhone} />
            <b>{phone}</b>
          </ResumePreviewLibraInline>
          <Spacer y={4} />
        </>
      )}
      {fullAddress && (
        <>
          <ResumePreviewLibraInline>
            <ResumePreviewLibraIcon icon={faLocationDot} />
            <ResumePreviewSmall>{fullAddress}</ResumePreviewSmall>
          </ResumePreviewLibraInline>
          <Spacer y={4} />
        </>
      )}
      {linkedinPath ? (
        <>
          <ResumePreviewLibraInline>
            <ResumePreviewLibraIcon icon={faLinkedin} />
            <ResumePreviewSmall>
              <span
                style={{
                  color: 'var(--design-primary-color)',
                }}
              >
                /in/{linkedinPath}
              </span>
            </ResumePreviewSmall>
          </ResumePreviewLibraInline>
          <Spacer y={4} />
        </>
      ) : null}
      {githubPath ? (
        <>
          <ResumePreviewLibraInline>
            <ResumePreviewLibraIcon icon={faGithub} />
            <ResumePreviewSmall>
              <span
                style={{
                  color: 'var(--design-primary-color)',
                }}
              >
                /{githubPath}
              </span>
            </ResumePreviewSmall>
          </ResumePreviewLibraInline>
          <Spacer y={4} />
        </>
      ) : null}
    </ResumePreviewBlock>
  );
};

type ResumePreviewLibraContactProps = {
  email?: string;
  phone?: string;
  fullAddress?: string;
  linkedinUrl?: string;
  githubUrl?: string;
};

export const ResumePreviewLibraExtra = (basic: ResumeBasicStore) => {
  const { nationality, drivingLicense, dateOfBirth, placeOfBirth, workRightUK, workRightEU, availability } = basic;
  let birthDay = '';
  let workRight = '';
  if (dateOfBirth && placeOfBirth) {
    birthDay = `Born on ${dateOfBirth}, ${placeOfBirth}`;
  } else {
    if (dateOfBirth) birthDay = `Date of Birth: ${dateOfBirth}`;
    if (placeOfBirth) birthDay = `Place of Birth: ${placeOfBirth}`;
  }
  if (workRightEU && workRightUK) {
    workRight = `Right to work in EU and UK`;
  } else {
    if (workRightUK) workRight = `Right to work in UK`;
    if (workRightEU) workRight = `Right to work in EU`;
  }

  return (
    <>
      {nationality && (
        <div>
          Nationality: <b>{nationality}</b>
        </div>
      )}
      {drivingLicense && (
        <div>
          Driving License: <b>${drivingLicense}</b>
        </div>
      )}
      {birthDay && <div>{birthDay}</div>}
      {availability && (
        <div>
          Availability: <b>{availability}</b>
        </div>
      )}
      {workRight && (
        <div>
          <b>{workRight}</b>
        </div>
      )}
    </>
  );
};

export const ResumePreviewLibraSummary = ({
  children,
  paragraphTextAlignment,
  hasParagraphIndendation,
}: {
  children?: React.ReactNode;
  paragraphTextAlignment: 'justify' | 'right' | 'left';
  hasParagraphIndendation: boolean;
}) => {
  if (!children) return null;
  return (
    <ResumePreviewSummaryContent
      $paragraphTextAlignment={paragraphTextAlignment}
      $hasParagraphIndendation={hasParagraphIndendation}
      dangerouslySetInnerHTML={{ __html: children }}
      style={{
        marginTop: '1em',
        marginBottom: '1em',
      }}
    ></ResumePreviewSummaryContent>
  );
};

export const ResumePreviewLibraEmployment = ({ item }: { item: ResumeEmploymentStore }) => {
  return (
    <ResumePreviewBlock
      style={{
        marginTop: '.5em',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            marginBottom: '0.2em',
            fontSize: '1.2em',
          }}
        >
          <b>{item.position}</b>
        </div>
        <div
          style={{
            textTransform: 'uppercase',
            marginBottom: '0.2em',
          }}
        >
          <i>{item.company}</i>
        </div>
        <div>
          <i>
            {item.startDate}
            {item.startDate && item.endDate ? ' - ' : null}
            {item.endDate}
            {(item.startDate || item.endDate) && item.location ? ' - ' : null}
            {item.location}
          </i>
        </div>
      </div>
      <ResumePreviewSummaryContent
        $paragraphTextAlignment={'left'}
        $hasParagraphIndendation={false}
        dangerouslySetInnerHTML={{ __html: item?.highlights }}
      ></ResumePreviewSummaryContent>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewLibraEducation = ({ item }: { item: ResumeEducationStore }) => {
  return (
    <ResumePreviewBlock
      style={{
        marginTop: '.5em',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            marginBottom: '0.5em',
          }}
        >
          <b>
            {item.studyType}
            {item.studyType && item.area ? ' in ' : null}
            {item.area}
          </b>
        </div>
        <div
          style={{
            textTransform: 'uppercase',
            marginBottom: '0.2em',
          }}
        >
          <i>{item.institution}</i>
        </div>
        <div>
          <i>
            {item.startDate}
            {item.startDate && item.endDate ? ' - ' : null}
            {item.endDate}
            {(item.startDate || item.endDate) && item.location ? ' - ' : null}
            {item.location}
            {(item.startDate || item.endDate || item.score) && item.score ? ' | ' : null}
          </i>
          {item.score ? `Score: ${item.score}` : null}
        </div>
      </div>
      <ResumePreviewSummaryContent
        $paragraphTextAlignment={'left'}
        $hasParagraphIndendation={false}
        dangerouslySetInnerHTML={{ __html: item?.highlights }}
      ></ResumePreviewSummaryContent>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewLibraProject = ({ item }: { item: ResumeProjectStore }) => {
  return (
    <ResumePreviewBlock
      style={{
        marginBottom: '1em',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            marginBottom: '0.5em',
          }}
        >
          <b>{item.name}</b>
        </div>
        <div>
          {item.date}
          {item.date && item.url ? ' | ' : null}
          {item.url}
        </div>
      </div>
      <ResumePreviewSummaryContent
        $paragraphTextAlignment={'right'}
        $hasParagraphIndendation={false}
        dangerouslySetInnerHTML={{ __html: item?.highlights }}
      ></ResumePreviewSummaryContent>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewLibraCertifications = ({
  title,
  items,
}: {
  title: string;
  items: ResumeCertificationStore[];
}) => {
  return (
    <div
      style={{
        marginBottom: '1em',
      }}
    >
      <ResumePreviewLibraSection section="certifications">{title}</ResumePreviewLibraSection>
      {items.map((item, index) => {
        return (
          <ResumePreviewBlock
            key={index}
            style={{
              marginTop: '.5em',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  marginBottom: '0.5em',
                }}
              >
                <b>{item.title}</b>
              </div>
              <div
                style={{
                  textTransform: 'uppercase',
                  marginBottom: '0.2em',
                }}
              >
                <i>{item.issuer}</i>
              </div>
              <div>
                <i>{item.date}</i>
                {item.date && item.url ? ' | ' : null} {item.url}
              </div>
            </div>
          </ResumePreviewBlock>
        );
      })}
    </div>
  );
};

export const ResumePreviewLibraLanguages = ({ title, items }: { title: string; items: ResumeLanguageStore[] }) => {
  return (
    <>
      <ResumePreviewLibraSection section="languages">{title}</ResumePreviewLibraSection>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '1em',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.1em',
            flex: '1 0',
          }}
        >
          {items
            .filter((_item, index) => {
              return index % 2 === 0;
            })
            .map((item, index) => {
              const { language, proficiency } = item;
              const languageLabel = getProficiencyLabel(proficiency);
              return (
                <ResumePreviewLibraInline key={index}>
                  <b>{language}</b>:<span>{languageLabel}</span>
                </ResumePreviewLibraInline>
              );
            })}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.1em',
            flex: '1 0',
          }}
        >
          {items
            .filter((_item, index) => {
              return index % 2 === 1;
            })
            .map((item, index) => {
              const { language, proficiency } = item;
              const languageLabel = getProficiencyLabel(proficiency);
              return (
                <ResumePreviewLibraInline key={index}>
                  <b>{language}</b>:<span>{languageLabel}</span>
                </ResumePreviewLibraInline>
              );
            })}
        </div>
      </div>
    </>
  );
};

export const ResumePreviewLibraHobby = ({ title, content }: { title: string; content: string }) => {
  return (
    <>
      <ResumePreviewLibraSection section="hobby">{title}</ResumePreviewLibraSection>
      <ResumePreviewHtml content={content} />
    </>
  );
};

export const ResumePreviewLibraSkills = ({ title, items }: { title: string; items: ResumeSkillStore[] }) => {
  return (
    <>
      <ResumePreviewLibraSection section="skills">{title}</ResumePreviewLibraSection>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '1em',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.1em',
            flex: '1 0',
          }}
        >
          {items
            .filter((_item, index) => {
              return index % 2 === 0;
            })
            .map((item, index) => {
              const { name, level } = item;
              const levelLabel = getLevelLabel(level);
              return (
                <ResumePreviewLibraInline key={index}>
                  <b>{name}</b>:<span>{levelLabel}</span>
                </ResumePreviewLibraInline>
              );
            })}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.1em',
            flex: '1 0',
          }}
        >
          {items
            .filter((_item, index) => {
              return index % 2 === 1;
            })
            .map((item, index) => {
              const { name, level } = item;
              const levelLabel = getLevelLabel(level);
              return (
                <ResumePreviewLibraInline key={index}>
                  <b>{name}</b>:<span>{levelLabel}</span>
                </ResumePreviewLibraInline>
              );
            })}
        </div>
      </div>
    </>
  );
};

export const ResumePreviewLibraReferences = ({
  title,
  items,
  onDemand,
}: {
  title: string;
  items: ResumeReferenceStore[];
  onDemand?: boolean;
}) => {
  return (
    <>
      <ResumePreviewLibraSection section="references">{title}</ResumePreviewLibraSection>
      {onDemand ? (
        <b>References available on demand</b>
      ) : (
        items.map((item, index) => {
          const { name, email, phone, company, linkedIn } = item;
          return (
            <table
              key={index}
              style={{
                width: '100%',
                marginBottom: '1rem',
                borderCollapse: 'collapse',
                margin: '1rem 0',
              }}
            >
              <tr>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                  }}
                >
                  {name}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                  }}
                >
                  {company}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                  }}
                >
                  {email}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                  }}
                >
                  {phone}
                </td>
              </tr>
              <tr>
                <td
                  colspan={2}
                  style={{
                    border: '1px solid black',
                    padding: '2px 8px',
                    textAlign: 'center',
                  }}
                >
                  LinkedIn:
                  <span
                    style={{
                      color: 'var(--design-primary-color)',
                    }}
                  >
                    {' '}
                    : {linkedIn}
                  </span>
                </td>
              </tr>
            </table>
          );
        })
      )}
      <Spacer y={2} />
    </>
  );
};

export const ResumePreviewLibraLinks = ({ title, items }: { title: string; items: ResumeLinkStore[] }) => {
  return (
    <>
      <ResumePreviewLibraSection section="links">{title}</ResumePreviewLibraSection>
      <ResumePreviewLibraInline>
        {items.map((item, index) => {
          const { text, url } = item;
          return (
            <ResumePreviewLibraInline key={index}>
              <b>{text}</b>:<span>{url}</span>
            </ResumePreviewLibraInline>
          );
        })}
      </ResumePreviewLibraInline>
    </>
  );
};

export const ResumePreviewLibraCustom = ({ item }: { item: ResumeCustomStore }) => {
  const { header, subheader, highlights } = item;
  return (
    <ResumePreviewBlock>
      <ResumePreviewLarge>{header}</ResumePreviewLarge>
      <ResumePreviewlarge>{subheader}</ResumePreviewlarge>
      <>
        {highlights?.map((highlight, index) => {
          return <ResumePreviewHtml key={index} content={highlight as unknown as string} />;
        })}
      </>
    </ResumePreviewBlock>
  );
};

export const ResumePreviewLibraPageBreak = styled.div``;
ResumePreviewLibraPageBreak.displayName = 'PageBreak';

export const ResumePreviewLibraSection = ({ children, section }: { children: React.ReactNode; section: string }) => {
  const icon = getIcon(section);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1em',
        fontSize: '1.8em',
        gap: '.5em',
      }}
    >
      {icon}
      {children}
    </div>
  );
};

/* Custom styles */
const ResumePreviewLibraBothSide = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ResumePreviewLibraInline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.4em;
`;

export const ResumePreviewLibraHeader = styled.div`
  display: flex;
`;

export const ResumePreviewLibraHeaderLeft = styled.div`
  flex: 7 0;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

export const ResumePreviewLibraHeaderRight = styled.div`
  flex: 10 0;
`;

const ResumePreviewLibraIcon = styled(FontAwesomeIcon)`
  color: var(--design-primary-color);
  width: 0.9em;
`;

const getIcon = (section: string) => {
  switch (section) {
    case 'employments':
      return <ResumePreviewLibraIcon icon={faBriefcase} />;
    case 'internships':
      return <ResumePreviewLibraIcon icon={faBriefcase} />;
    case 'educations':
      return <ResumePreviewLibraIcon icon={faGraduationCap} />;
    case 'certifications':
      return <ResumePreviewLibraIcon icon={faThumbsUp} />;
    case 'languages':
      return <ResumePreviewLibraIcon icon={faGlobe} />;
    case 'skills':
      return <ResumePreviewLibraIcon icon={faWrench} />;
    case 'hobbies':
      return <ResumePreviewLibraIcon icon={faBicycle} />;
    case 'links':
      return <ResumePreviewLibraIcon icon={faLink} />;
    case 'references':
      return <ResumePreviewLibraIcon icon={faComment} />;
    case 'custom':
      return <ResumePreviewLibraIcon icon={faStar} />;
    case 'projects':
      return <ResumePreviewLibraIcon icon={faPuzzlePiece} />;
    default:
      return null;
  }
};
